@charset "UTF-8";
/* $colors
 ------------------------------------------*/
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #0275d8;
  --indigo: #6610f2;
  --purple: #613d7c;
  --pink: #ff5b77;
  --red: #d9534f;
  --orange: #f0ad4e;
  --yellow: #ffd500;
  --green: #5cb85c;
  --teal: #5bc0de;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #304ffe;
  --secondary: #ffffff;
  --success: #00c853;
  --info: #0091ea;
  --warning: #ffd600;
  --danger: #d50000;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Tahoma", sans-serif;
  --font-family-monospace: "Space Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Tahoma", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #304ffe;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0123e0;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: "Space Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.125rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #ff5b77;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.0625rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.87);
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c5ceff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #93a3fe;
}

.table-hover .table-primary:hover {
  background-color: #acb8ff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #acb8ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white;
}

.table-hover .table-secondary:hover {
  background-color: #f2f2f2;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f2f2f2;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f0cf;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ae2a6;
}

.table-hover .table-success:hover {
  background-color: #a3ecc1;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a3ecc1;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e0f9;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ac6f4;
}

.table-hover .table-info:hover {
  background-color: #a0d6f7;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a0d6f7;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff4b8;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffea7a;
}

.table-hover .table-warning:hover {
  background-color: #fff09f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff09f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f3b8b8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e97a7a;
}

.table-hover .table-danger:hover {
  background-color: #efa2a2;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #efa2a2;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #afbbff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.0625rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00c853;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 200, 83, 0.9);
  border-radius: 0.125rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00c853;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300c853' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00c853;
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00c853;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300c853' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #00c853;
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00c853;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00c853;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00c853;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00fb68;
  background-color: #00fb68;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00c853;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00c853;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00c853;
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d50000;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(213, 0, 0, 0.9);
  border-radius: 0.125rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d50000;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d50000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d50000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d50000;
  box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d50000;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d50000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d50000' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d50000;
  box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d50000;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d50000;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d50000;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff0909;
  background-color: #ff0909;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d50000;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d50000;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d50000;
  box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.532rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #304ffe;
  border-color: #304ffe;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0a2ffe;
  border-color: #0127fa;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0a2ffe;
  border-color: #0127fa;
  box-shadow: 0 0 0 0.2rem rgba(79, 105, 254, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #304ffe;
  border-color: #304ffe;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0127fa;
  border-color: #0125ed;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 105, 254, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-secondary:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #00c853;
  border-color: #00c853;
}
.btn-success:hover {
  color: #fff;
  background-color: #00a243;
  border-color: #00953e;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #00a243;
  border-color: #00953e;
  box-shadow: 0 0 0 0.2rem rgba(38, 208, 109, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00c853;
  border-color: #00c853;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00953e;
  border-color: #008839;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 208, 109, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #0091ea;
  border-color: #0091ea;
}
.btn-info:hover {
  color: #fff;
  background-color: #0079c4;
  border-color: #0071b7;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #0079c4;
  border-color: #0071b7;
  box-shadow: 0 0 0 0.2rem rgba(38, 162, 237, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #0091ea;
  border-color: #0091ea;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0071b7;
  border-color: #0069aa;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 162, 237, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffd600;
  border-color: #ffd600;
}
.btn-warning:hover {
  color: #212529;
  background-color: #d9b600;
  border-color: #ccab00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #d9b600;
  border-color: #ccab00;
  box-shadow: 0 0 0 0.2rem rgba(222, 187, 6, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffd600;
  border-color: #ffd600;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ccab00;
  border-color: #bfa100;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 187, 6, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #d50000;
  border-color: #d50000;
}
.btn-danger:hover {
  color: #fff;
  background-color: #af0000;
  border-color: #a20000;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #af0000;
  border-color: #a20000;
  box-shadow: 0 0 0 0.2rem rgba(219, 38, 38, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #d50000;
  border-color: #d50000;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a20000;
  border-color: #950000;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 38, 38, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #304ffe;
  border-color: #304ffe;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #304ffe;
  border-color: #304ffe;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #304ffe;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #304ffe;
  border-color: #304ffe;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.5);
}

.btn-outline-secondary {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-success {
  color: #00c853;
  border-color: #00c853;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00c853;
  border-color: #00c853;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00c853;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00c853;
  border-color: #00c853;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.5);
}

.btn-outline-info {
  color: #0091ea;
  border-color: #0091ea;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #0091ea;
  border-color: #0091ea;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 145, 234, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #0091ea;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0091ea;
  border-color: #0091ea;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 145, 234, 0.5);
}

.btn-outline-warning {
  color: #ffd600;
  border-color: #ffd600;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffd600;
  border-color: #ffd600;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 214, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffd600;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffd600;
  border-color: #ffd600;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 214, 0, 0.5);
}

.btn-outline-danger {
  color: #d50000;
  border-color: #d50000;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #d50000;
  border-color: #d50000;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d50000;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d50000;
  border-color: #d50000;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #304ffe;
  text-decoration: none;
}
.btn-link:hover {
  color: #0123e0;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1.032rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.282rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.0625rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.125rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #304ffe;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.125rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.0625rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #304ffe;
  background-color: #304ffe;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #afbbff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #e2e6ff;
  border-color: #e2e6ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.125rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #304ffe;
  background-color: #304ffe;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 79, 254, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(48, 79, 254, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 79, 254, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 79, 254, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.125rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #afbbff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #afbbff;
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.125rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.125rem 0.125rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #304ffe;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #e2e6ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #304ffe;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #e2e6ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #304ffe;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #e2e6ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.125rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #304ffe;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.21875rem;
  padding-bottom: 0.21875rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.125rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.06);
  border-radius: 0.125rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  background-color: white;
  border-bottom: 0 solid rgba(0, 0, 0, 0.06);
}
.card-header:first-child {
  border-radius: 0.125rem 0.125rem 0 0;
}

.card-footer {
  padding: 1rem 1.25rem;
  background-color: white;
  border-top: 0 solid rgba(0, 0, 0, 0.06);
}
.card-footer:last-child {
  border-radius: 0 0 0.125rem 0.125rem;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.125rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.card-deck .card {
  margin-bottom: 7.5px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 7.5px;
    margin-bottom: 0;
    margin-left: 7.5px;
  }
}

.card-group > .card {
  margin-bottom: 7.5px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.125rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.125rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #304ffe;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0123e0;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #304ffe;
  border-color: #304ffe;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.0625rem;
  border-bottom-left-radius: 0.0625rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.0625rem;
  border-bottom-right-radius: 0.0625rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #304ffe;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0127fa;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #ffffff;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #00c853;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #00953e;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 200, 83, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #0091ea;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #0071b7;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 145, 234, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffd600;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ccab00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 214, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #d50000;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #a20000;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.125rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #192984;
  background-color: #d6dcff;
  border-color: #c5ceff;
}
.alert-primary hr {
  border-top-color: #acb8ff;
}
.alert-primary .alert-link {
  color: #111c59;
}

.alert-secondary {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-secondary hr {
  border-top-color: #f2f2f2;
}
.alert-secondary .alert-link {
  color: #6c6c6c;
}

.alert-success {
  color: #00682b;
  background-color: #ccf4dd;
  border-color: #b8f0cf;
}
.alert-success hr {
  border-top-color: #a3ecc1;
}
.alert-success .alert-link {
  color: #003516;
}

.alert-info {
  color: #004b7a;
  background-color: #cce9fb;
  border-color: #b8e0f9;
}
.alert-info hr {
  border-top-color: #a0d6f7;
}
.alert-info .alert-link {
  color: #002c47;
}

.alert-warning {
  color: #856f00;
  background-color: #fff7cc;
  border-color: #fff4b8;
}
.alert-warning hr {
  border-top-color: #fff09f;
}
.alert-warning .alert-link {
  color: #524400;
}

.alert-danger {
  color: #6f0000;
  background-color: #f7cccc;
  border-color: #f3b8b8;
}
.alert-danger hr {
  border-top-color: #efa2a2;
}
.alert-danger .alert-link {
  color: #3c0000;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.125rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #304ffe;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.125rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: rgba(0, 0, 0, 0.87);
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #304ffe;
  border-color: #304ffe;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.125rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.125rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #192984;
  background-color: #c5ceff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #192984;
  background-color: #acb8ff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #192984;
  border-color: #192984;
}

.list-group-item-secondary {
  color: #858585;
  background-color: white;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-success {
  color: #00682b;
  background-color: #b8f0cf;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #00682b;
  background-color: #a3ecc1;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #00682b;
  border-color: #00682b;
}

.list-group-item-info {
  color: #004b7a;
  background-color: #b8e0f9;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #004b7a;
  background-color: #a0d6f7;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #004b7a;
  border-color: #004b7a;
}

.list-group-item-warning {
  color: #856f00;
  background-color: #fff4b8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856f00;
  background-color: #fff09f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856f00;
  border-color: #856f00;
}

.list-group-item-danger {
  color: #6f0000;
  background-color: #f3b8b8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #6f0000;
  background-color: #efa2a2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #6f0000;
  border-color: #6f0000;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Tahoma", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.125rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Tahoma", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.25rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.25rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.25rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: rgba(0, 0, 0, 0.87);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #304ffe !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0127fa !important;
}

.bg-secondary {
  background-color: #ffffff !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important;
}

.bg-success {
  background-color: #00c853 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00953e !important;
}

.bg-info {
  background-color: #0091ea !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0071b7 !important;
}

.bg-warning {
  background-color: #ffd600 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ccab00 !important;
}

.bg-danger {
  background-color: #d50000 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a20000 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #304ffe !important;
}

.border-secondary {
  border-color: #ffffff !important;
}

.border-success {
  border-color: #00c853 !important;
}

.border-info {
  border-color: #0091ea !important;
}

.border-warning {
  border-color: #ffd600 !important;
}

.border-danger {
  border-color: #d50000 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.0625rem !important;
}

.rounded {
  border-radius: 0.125rem !important;
}

.rounded-top {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.rounded-right {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-left {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-lg {
  border-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: "Space Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #304ffe !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0123e0 !important;
}

.text-secondary {
  color: #ffffff !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important;
}

.text-success {
  color: #00c853 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #007c33 !important;
}

.text-info {
  color: #0091ea !important;
}

a.text-info:hover, a.text-info:focus {
  color: #00629e !important;
}

.text-warning {
  color: #ffd600 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b39600 !important;
}

.text-danger {
  color: #d50000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #890000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.theme-light {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .bg-body {
  background-color: #ffffff;
}
.theme-light .text-color {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .text-muted {
  color: rgba(0, 0, 0, 0.3) !important;
}
.theme-light code,
.theme-light kbd {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light pre {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .card,
.theme-light .list-group-item,
.theme-light ngb-accordion > .card > .card-body {
  background-color: white;
}
.theme-light .card .card-header {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .card-header::after {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-light .card-subtitle {
  color: rgba(0, 0, 0, 0.3);
}
.theme-light .btn-secondary {
  color: #212529;
  background-color: white;
  border-color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .btn-secondary:hover {
  color: #212529;
  background-color: #ececec;
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-light .btn-secondary:focus, .theme-light .btn-secondary.focus {
  color: #212529;
  background-color: #ececec;
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 0 0 0.2rem rgba(6, 7, 8, 0.5);
}
.theme-light .btn-secondary.disabled, .theme-light .btn-secondary:disabled {
  color: #212529;
  background-color: white;
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-light .btn-secondary:not(:disabled):not(.disabled):active, .theme-light .btn-secondary:not(:disabled):not(.disabled).active, .show > .theme-light .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-light .btn-secondary:not(:disabled):not(.disabled):active:focus, .theme-light .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .theme-light .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(6, 7, 8, 0.5);
}
.theme-light .btn-outline-secondary {
  color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .btn-outline-secondary:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .btn-outline-secondary:focus, .theme-light .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.theme-light .btn-outline-secondary.disabled, .theme-light .btn-outline-secondary:disabled {
  color: rgba(0, 0, 0, 0.12);
  background-color: transparent;
}
.theme-light .btn-outline-secondary:not(:disabled):not(.disabled):active, .theme-light .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .theme-light .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .theme-light .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .theme-light .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.theme-light .page-link {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .ngb-dp-header {
  background-color: white;
}
.theme-light .progress {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-light .nav-tabs {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-light .nav-tabs .nav-link:hover, .theme-light .nav-tabs .nav-link:focus {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .card .nav-tabs .nav-link.active,
.theme-light .card .nav-tabs .nav-item.show .nav-link {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) white;
}
.theme-light .modal-content {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .modal-header {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-light .modal-footer {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-light .form-control {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #afbbff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.theme-light .form-control::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.theme-light .form-control:disabled, .theme-light .form-control[readonly] {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light select.form-control:focus::-ms-value {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .form-check.disabled .form-check-label {
  color: rgba(0, 0, 0, 0.3);
}
.theme-light .table th,
.theme-light .table td {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-light .table thead th {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-light .table tbody + tbody {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-light .table .table {
  background-color: #ffffff;
}
.theme-light .table-bordered {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .table-bordered th,
.theme-light .table-bordered td {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.theme-light .table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (max-width: 991.98px) {
  .theme-light .no-more-tables tr {
    border-color: rgba(0, 0, 0, 0.12);
  }
  .theme-light .no-more-tables td {
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
}
.theme-light .ng-sidebar__content .main-header {
  background: linear-gradient(to right, white 25%, #37ac94);
}
.theme-light .ng-sidebar__content .main-header .nav-link .badge {
  border: 2px solid white;
}
.theme-light .ng-sidebar__content .main-header .hamburger-icon:before, .theme-light .ng-sidebar__content .main-header .hamburger-icon:after,
.theme-light .ng-sidebar__content .main-header .hamburger-icon span {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-light .ng-sidebar__content .main-header .nav-link,
.theme-light .ng-sidebar__content .main-header .navbar-heading {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .ng-sidebar__content .main-header .nav-link:hover, .theme-light .ng-sidebar__content .main-header .nav-link:focus,
.theme-light .ng-sidebar__content .main-header .navbar-heading:hover,
.theme-light .ng-sidebar__content .main-header .navbar-heading:focus {
  color: black;
}
.theme-light .ng-sidebar__content .main-header .dropdown.show > .nav-link {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .hamburger-icon:before, .theme-light .hamburger-icon:after,
.theme-light .hamburger-icon span {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-light .nav-divider {
  background: rgba(0, 0, 0, 0.12);
}
.theme-light .ng-sidebar__content .ng-sidebar {
  background-color: transparent;
}
.theme-light .sidebar-panel.ng-sidebar {
  background-color: #fff;
}
.theme-light .sidebar-panel.ng-sidebar .hamburger-icon:before, .theme-light .sidebar-panel.ng-sidebar .hamburger-icon:after,
.theme-light .sidebar-panel.ng-sidebar .hamburger-icon span {
  background-color: white;
}
.theme-light .sidebar-panel.ng-sidebar .navigation-link {
  color: rgba(255, 255, 255, 0.56);
}
.theme-light .sidebar-panel.ng-sidebar .navigation-link:hover, .theme-light .sidebar-panel.ng-sidebar .navigation-link:focus {
  color: white;
}
.theme-light .sidebar-panel.ng-sidebar .navigation-item.open > a {
  color: white;
}
.theme-light ngb-accordion > .card {
  background-color: transparent;
}
.theme-light ngb-accordion > .card .card-header {
  background-color: white;
}
.theme-light .activity-stream::before {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-light .timeline::before {
  background: rgba(0, 0, 0, 0.12);
}
.theme-light .timeline .timeline-icon::after {
  background: white;
}
.theme-light .timeline .timeline-content > .card::before {
  border-color: transparent white transparent transparent !important;
}
.theme-light .timeline .timeline-content > .card::after {
  border-color: transparent white transparent transparent !important;
}
@media (min-width: 992px) {
  .theme-light .timeline:not(.stacked) .timeline-card:nth-child(even) .timeline-content > .card::before {
    border-color: transparent transparent transparent white !important;
  }
  .theme-light .timeline:not(.stacked) .timeline-card:nth-child(even) .timeline-content > .card::after {
    border-color: transparent transparent transparent white !important;
  }
}
.theme-light .taskboard .taskboard-task {
  background: white !important;
}
.theme-light .taskboard .taskboard-header {
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme-light .email-panel .list-group-item a {
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme-light .email-panel .list-group-item.selected a {
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme-light .cal-day-view .cal-hour-rows {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.3);
}
.theme-light .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
.theme-light .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .cal-day-view .cal-hour-segment:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .cal-month-view .cal-cell-row .cal-cell:hover,
.theme-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(0, 0, 0, 0.05);
}
.theme-light .cal-month-view .cal-days {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .cal-month-view .cal-day-cell:not(:last-child) {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .cal-month-view .cal-days .cal-cell-row {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .cal-week-view .cal-day-headers {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .cal-week-view .cal-day-headers .cal-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .ngx-datatable {
  background-color: white;
  /**
   * Header Styles
   */
  /**
   * Footer Styles
   */
}
.theme-light .ngx-datatable.striped .datatable-row-odd {
  background: rgba(0, 0, 0, 0.05);
}
.theme-light .ngx-datatable.single-selection .datatable-body-row.active, .theme-light .ngx-datatable.single-selection .datatable-body-row.active .datatable-row-group, .theme-light .ngx-datatable.multi-selection .datatable-body-row.active, .theme-light .ngx-datatable.multi-selection .datatable-body-row.active .datatable-row-group {
  background: theme-colors("primary");
}
.theme-light .ngx-datatable.single-selection .datatable-body-row.active:hover, .theme-light .ngx-datatable.single-selection .datatable-body-row.active:hover .datatable-row-group, .theme-light .ngx-datatable.multi-selection .datatable-body-row.active:hover, .theme-light .ngx-datatable.multi-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .ngx-datatable.single-selection .datatable-body-row.active:focus, .theme-light .ngx-datatable.single-selection .datatable-body-row.active:focus .datatable-row-group, .theme-light .ngx-datatable.multi-selection .datatable-body-row.active:focus, .theme-light .ngx-datatable.multi-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .ngx-datatable:not(.cell-selection) .datatable-body-row:hover, .theme-light .ngx-datatable:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: transparent;
}
.theme-light .ngx-datatable:not(.cell-selection) .datatable-body-row:focus, .theme-light .ngx-datatable:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .ngx-datatable.cell-selection .datatable-body-cell:hover, .theme-light .ngx-datatable.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}
.theme-light .ngx-datatable.cell-selection .datatable-body-cell:focus, .theme-light .ngx-datatable.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-light .ngx-datatable.cell-selection .datatable-body-cell.active, .theme-light .ngx-datatable.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: theme-colors("primary");
}
.theme-light .ngx-datatable.cell-selection .datatable-body-cell.active:hover, .theme-light .ngx-datatable.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: theme-colors("primary");
}
.theme-light .ngx-datatable.cell-selection .datatable-body-cell.active:focus, .theme-light .ngx-datatable.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: theme-colors("primary");
}
.theme-light .ngx-datatable .datatable-header .datatable-row-left,
.theme-light .ngx-datatable .datatable-body .datatable-row-left,
.theme-light .ngx-datatable .datatable-header .datatable-row-right,
.theme-light .ngx-datatable .datatable-body .datatable-row-right {
  background-color: white;
}
.theme-light .ngx-datatable .datatable-header {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-light .ngx-datatable .datatable-header .datatable-header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .ngx-datatable .datatable-header .resize-handle {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.theme-light .ngx-datatable .datatable-footer {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-light .my-drop-zone {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.theme-light .ql-toolbar.ql-snow,
.theme-light .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-light .ql-snow .ql-picker .ql-picker-label {
  color: rgba(0, 0, 0, 0.87);
}
.theme-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.theme-light .ql-snow .ql-fill,
.theme-light .ql-snow .ql-stroke.ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.theme-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.theme-light .ngx-charts .gridline-path {
  stroke: rgba(0, 0, 0, 0.05);
}
.theme-light .ngx-charts .grid-panel.odd rect {
  fill: rgba(0, 0, 0, 0.04);
}
.theme-light .ngx-charts .gauge .background-arc path {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-light .ngx-charts .gauge .gauge-tick path {
  stroke: rgba(0, 0, 0, 0.12);
}
.theme-light .ngx-charts .gauge .gauge-tick text {
  fill: rgba(0, 0, 0, 0.87);
}
.theme-light .ngx-charts .linear-gauge .background-bar path {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-light .ngx-charts .linear-gauge .units {
  fill: rgba(0, 0, 0, 0.87);
}
.theme-light .ngx-charts .timeline .brush-background {
  fill: rgba(0, 0, 0, 0.12);
}
.theme-light .ngx-charts .timeline .brush .selection {
  fill: rgba(0, 0, 0, 0.12);
  stroke: #aaa;
}
.theme-light .ngx-charts .number-card .number-card-label {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.87);
}

.theme-dark {
  background-color: #3a3a3a;
  color: white;
}
.theme-dark .bg-body {
  background-color: #3a3a3a;
}
.theme-dark .text-color {
  color: white;
}
.theme-dark .text-muted {
  color: rgba(255, 255, 255, 0.3) !important;
}
.theme-dark code,
.theme-dark kbd {
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark pre {
  color: white;
}
.theme-dark .card,
.theme-dark .list-group-item,
.theme-dark ngb-accordion > .card > .card-body {
  background-color: #424242;
}
.theme-dark .card .card-header {
  color: white;
}
.theme-dark .card-header::after {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .card-subtitle {
  color: rgba(255, 255, 255, 0.3);
}
.theme-dark .btn-secondary {
  color: #fff;
  background-color: #424242;
  border-color: white;
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .btn-secondary:hover {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #e6e6e6;
}
.theme-dark .btn-secondary:focus, .theme-dark .btn-secondary.focus {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.theme-dark .btn-secondary.disabled, .theme-dark .btn-secondary:disabled {
  color: #fff;
  background-color: #424242;
  border-color: white;
}
.theme-dark .btn-secondary:not(:disabled):not(.disabled):active, .theme-dark .btn-secondary:not(:disabled):not(.disabled).active, .show > .theme-dark .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #292929;
  border-color: #dfdfdf;
}
.theme-dark .btn-secondary:not(:disabled):not(.disabled):active:focus, .theme-dark .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .theme-dark .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.theme-dark .btn-outline-secondary {
  color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .btn-outline-secondary:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .btn-outline-secondary:focus, .theme-dark .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.theme-dark .btn-outline-secondary.disabled, .theme-dark .btn-outline-secondary:disabled {
  color: rgba(255, 255, 255, 0.12);
  background-color: transparent;
}
.theme-dark .btn-outline-secondary:not(:disabled):not(.disabled):active, .theme-dark .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .theme-dark .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .theme-dark .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .theme-dark .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.theme-dark .page-link {
  color: white;
  background-color: #424242;
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .ngb-dp-header {
  background-color: #424242;
}
.theme-dark .progress {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .nav-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .nav-tabs .nav-link:hover, .theme-dark .nav-tabs .nav-link:focus {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .card .nav-tabs .nav-link.active,
.theme-dark .card .nav-tabs .nav-item.show .nav-link {
  color: white;
  background-color: #424242;
  border-color: rgba(255, 255, 255, 0.12) rgba(255, 255, 255, 0.12) #424242;
}
.theme-dark .modal-content {
  background-color: #424242;
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .modal-header {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .form-control {
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #afbbff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 79, 254, 0.25);
}
.theme-dark .form-control::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.theme-dark .form-control:disabled, .theme-dark .form-control[readonly] {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark select.form-control:focus::-ms-value {
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .form-check.disabled .form-check-label {
  color: rgba(255, 255, 255, 0.3);
}
.theme-dark .table th,
.theme-dark .table td {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .table thead th {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .table tbody + tbody {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .table .table {
  background-color: #3a3a3a;
}
.theme-dark .table-bordered {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .table-bordered th,
.theme-dark .table-bordered td {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-dark .table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (max-width: 991.98px) {
  .theme-dark .no-more-tables tr {
    border-color: rgba(255, 255, 255, 0.12);
  }
  .theme-dark .no-more-tables td {
    border-bottom-color: rgba(255, 255, 255, 0.12);
  }
}
.theme-dark .ng-sidebar__content .main-header {
  background: linear-gradient(to right, white 25%, #37ac94);
}
.theme-dark .ng-sidebar__content .main-header .nav-link .badge {
  border: 2px solid #424242;
}
.theme-dark .ng-sidebar__content .main-header .hamburger-icon:before, .theme-dark .ng-sidebar__content .main-header .hamburger-icon:after,
.theme-dark .ng-sidebar__content .main-header .hamburger-icon span {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-dark .ng-sidebar__content .main-header .nav-link,
.theme-dark .ng-sidebar__content .main-header .navbar-heading {
  color: rgba(255, 255, 255, 0.7);
}
.theme-dark .ng-sidebar__content .main-header .nav-link:hover, .theme-dark .ng-sidebar__content .main-header .nav-link:focus,
.theme-dark .ng-sidebar__content .main-header .navbar-heading:hover,
.theme-dark .ng-sidebar__content .main-header .navbar-heading:focus {
  color: white;
}
.theme-dark .ng-sidebar__content .main-header .dropdown.show > .nav-link {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}
.theme-dark .hamburger-icon:before, .theme-dark .hamburger-icon:after,
.theme-dark .hamburger-icon span {
  background-color: white;
}
.theme-dark .nav-divider {
  background: rgba(255, 255, 255, 0.12);
}
.theme-dark .ng-sidebar__content .ng-sidebar {
  background-color: transparent;
}
.theme-dark .sidebar-panel.ng-sidebar {
  background-color: #333333;
}
.theme-dark .sidebar-panel.ng-sidebar .hamburger-icon:before, .theme-dark .sidebar-panel.ng-sidebar .hamburger-icon:after,
.theme-dark .sidebar-panel.ng-sidebar .hamburger-icon span {
  background-color: white;
}
.theme-dark .sidebar-panel.ng-sidebar .navigation-link {
  color: rgba(255, 255, 255, 0.56);
}
.theme-dark .sidebar-panel.ng-sidebar .navigation-link:hover, .theme-dark .sidebar-panel.ng-sidebar .navigation-link:focus {
  color: white;
}
.theme-dark .sidebar-panel.ng-sidebar .navigation-item.open > a {
  color: white;
}
.theme-dark ngb-accordion > .card {
  background-color: transparent;
}
.theme-dark ngb-accordion > .card .card-header {
  background-color: #424242;
}
.theme-dark .activity-stream::before {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .timeline::before {
  background: rgba(255, 255, 255, 0.12);
}
.theme-dark .timeline .timeline-icon::after {
  background: #424242;
}
.theme-dark .timeline .timeline-content > .card::before {
  border-color: transparent #424242 transparent transparent !important;
}
.theme-dark .timeline .timeline-content > .card::after {
  border-color: transparent #424242 transparent transparent !important;
}
@media (min-width: 992px) {
  .theme-dark .timeline:not(.stacked) .timeline-card:nth-child(even) .timeline-content > .card::before {
    border-color: transparent transparent transparent #424242 !important;
  }
  .theme-dark .timeline:not(.stacked) .timeline-card:nth-child(even) .timeline-content > .card::after {
    border-color: transparent transparent transparent #424242 !important;
  }
}
.theme-dark .taskboard .taskboard-task {
  background: #424242 !important;
}
.theme-dark .taskboard .taskboard-header {
  color: white !important;
}
.theme-dark .email-panel .list-group-item a {
  color: white !important;
}
.theme-dark .email-panel .list-group-item.selected a {
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme-dark .cal-day-view .cal-hour-rows {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-dark .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
.theme-dark .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .cal-day-view .cal-hour-segment:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.theme-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-dark .cal-month-view .cal-days {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .cal-month-view .cal-day-cell:not(:last-child) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .cal-month-view .cal-days .cal-cell-row {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .cal-week-view .cal-day-headers {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .cal-week-view .cal-day-headers .cal-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .ngx-datatable {
  background-color: #424242;
  /**
   * Header Styles
   */
  /**
   * Footer Styles
   */
}
.theme-dark .ngx-datatable.striped .datatable-row-odd {
  background: rgba(255, 255, 255, 0.05);
}
.theme-dark .ngx-datatable.single-selection .datatable-body-row.active, .theme-dark .ngx-datatable.single-selection .datatable-body-row.active .datatable-row-group, .theme-dark .ngx-datatable.multi-selection .datatable-body-row.active, .theme-dark .ngx-datatable.multi-selection .datatable-body-row.active .datatable-row-group {
  background: theme-colors("primary");
}
.theme-dark .ngx-datatable.single-selection .datatable-body-row.active:hover, .theme-dark .ngx-datatable.single-selection .datatable-body-row.active:hover .datatable-row-group, .theme-dark .ngx-datatable.multi-selection .datatable-body-row.active:hover, .theme-dark .ngx-datatable.multi-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .ngx-datatable.single-selection .datatable-body-row.active:focus, .theme-dark .ngx-datatable.single-selection .datatable-body-row.active:focus .datatable-row-group, .theme-dark .ngx-datatable.multi-selection .datatable-body-row.active:focus, .theme-dark .ngx-datatable.multi-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .ngx-datatable:not(.cell-selection) .datatable-body-row:hover, .theme-dark .ngx-datatable:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: transparent;
}
.theme-dark .ngx-datatable:not(.cell-selection) .datatable-body-row:focus, .theme-dark .ngx-datatable:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .ngx-datatable.cell-selection .datatable-body-cell:hover, .theme-dark .ngx-datatable.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background: rgba(255, 255, 255, 0.04);
}
.theme-dark .ngx-datatable.cell-selection .datatable-body-cell:focus, .theme-dark .ngx-datatable.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-dark .ngx-datatable.cell-selection .datatable-body-cell.active, .theme-dark .ngx-datatable.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: theme-colors("primary");
}
.theme-dark .ngx-datatable.cell-selection .datatable-body-cell.active:hover, .theme-dark .ngx-datatable.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: theme-colors("primary");
}
.theme-dark .ngx-datatable.cell-selection .datatable-body-cell.active:focus, .theme-dark .ngx-datatable.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: theme-colors("primary");
}
.theme-dark .ngx-datatable .datatable-header .datatable-row-left,
.theme-dark .ngx-datatable .datatable-body .datatable-row-left,
.theme-dark .ngx-datatable .datatable-header .datatable-row-right,
.theme-dark .ngx-datatable .datatable-body .datatable-row-right {
  background-color: #424242;
}
.theme-dark .ngx-datatable .datatable-header {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .ngx-datatable .datatable-header .datatable-header-cell {
  color: white;
}
.theme-dark .ngx-datatable .datatable-header .resize-handle {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .ngx-datatable .datatable-footer {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .my-drop-zone {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.theme-dark .ql-toolbar.ql-snow,
.theme-dark .ql-container.ql-snow {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-dark .ql-snow .ql-picker .ql-picker-label {
  color: white;
}
.theme-dark .ql-snow .ql-stroke {
  stroke: white;
}
.theme-dark .ql-snow .ql-fill,
.theme-dark .ql-snow .ql-stroke.ql-fill {
  fill: white;
}
.theme-dark .ngx-charts text {
  fill: white;
}
.theme-dark .ngx-charts .gridline-path {
  stroke: rgba(255, 255, 255, 0.05);
}
.theme-dark .ngx-charts .grid-panel.odd rect {
  fill: rgba(255, 255, 255, 0.04);
}
.theme-dark .ngx-charts .gauge .background-arc path {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-dark .ngx-charts .gauge .gauge-tick path {
  stroke: rgba(255, 255, 255, 0.12);
}
.theme-dark .ngx-charts .gauge .gauge-tick text {
  fill: white;
}
.theme-dark .ngx-charts .linear-gauge .background-bar path {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-dark .ngx-charts .linear-gauge .units {
  fill: white;
}
.theme-dark .ngx-charts .timeline .brush-background {
  fill: rgba(255, 255, 255, 0.12);
}
.theme-dark .ngx-charts .timeline .brush .selection {
  fill: rgba(255, 255, 255, 0.12);
  stroke: #aaa;
}
.theme-dark .ngx-charts .number-card .number-card-label {
  font-size: 0.8rem;
  color: white;
}

ngb-accordion > .card {
  background-color: transparent;
  margin-bottom: 5px;
}
ngb-accordion > .card .card-header a {
  color: inherit;
}
ngb-accordion > .card .card-header::after {
  display: none;
}
ngb-accordion > .card .card-header.active::after {
  display: block;
}

.btn {
  font-size: 13px;
  letter-spacing: 0.02rem;
}

.input-group-btn > .btn {
  line-height: inherit;
}

.btn-icon {
  position: relative;
  overflow: hidden;
  min-height: 2.188rem;
  padding-left: 2.188rem;
  text-overflow: ellipsis;
  text-align: left;
}
.btn-icon i:first-child {
  line-height: 2.188rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2.188rem;
  text-align: center;
  font-size: 0.875rem;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  min-height: 3.188rem;
  padding-left: 3.188rem;
}
.btn-icon.btn-lg :first-child, .btn-group-lg > .btn-icon.btn :first-child {
  line-height: 3.188rem;
  width: 3.188rem;
  font-size: 2rem;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  min-height: 1.688rem;
  padding-left: 1.688rem;
}
.btn-icon.btn-sm :first-child, .btn-group-sm > .btn-icon.btn :first-child {
  line-height: 1.688rem;
  width: 1.688rem;
}

.btn-icon-icon {
  width: 2.188rem;
  height: 2.188rem;
  padding: 0;
  padding-left: 0 !important;
  position: relative;
  overflow: hidden;
  min-height: 2.188rem;
  padding-left: 2.188rem;
  text-overflow: ellipsis;
}
.btn-icon-icon i:first-child {
  line-height: 2.188rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2.188rem;
  text-align: center;
  font-size: 0.875rem;
}
.btn-icon-icon.btn-lg, .btn-group-lg > .btn-icon-icon.btn {
  min-height: 3.188rem;
  padding-left: 3.188rem;
}
.btn-icon-icon.btn-lg :first-child, .btn-group-lg > .btn-icon-icon.btn :first-child {
  line-height: 3.188rem;
  width: 3.188rem;
  font-size: 2rem;
}
.btn-icon-icon.btn-sm, .btn-group-sm > .btn-icon-icon.btn {
  min-height: 1.688rem;
  padding-left: 1.688rem;
}
.btn-icon-icon.btn-sm :first-child, .btn-group-sm > .btn-icon-icon.btn :first-child {
  line-height: 1.688rem;
  width: 1.688rem;
}
.btn-icon-icon :first-child {
  width: 100% !important;
  text-align: center;
}
.btn-icon-icon.btn-lg, .btn-group-lg > .btn-icon-icon.btn {
  width: 3.188rem;
  height: 3.188rem;
  padding-right: 0;
  padding-left: 0;
}
.btn-icon-icon.btn-sm, .btn-group-sm > .btn-icon-icon.btn {
  width: 1.688rem;
  height: 1.688rem;
  padding-right: 0;
  padding-left: 0;
}

.card {
  margin-bottom: 1rem;
}
.card.card-widget {
  height: auto;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  max-width: 100%;
  padding: 1rem;
}
.card.card-widget p {
  margin: 0;
  line-height: 1;
}
.card .card-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: "Montserrat", Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  font-size: 13px;
  background: transparent;
  color: #000;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.card .card-header::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  left: 15px;
  right: 15px;
  bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.card .card-header.no-shadow::after {
  border-bottom: 0;
}
.card .card-header .card-header-text .card-title {
  line-height: 1;
  font-size: 13px;
  font-weight: 400;
}
.card .card-header .card-header-text .card-subtitle {
  line-height: 1;
  font-size: 12px;
  text-transform: initial;
  font-weight: 300;
}
.card .card-header .card-controls {
  line-height: 1;
  right: 1rem;
}
.card .card-header .card-controls a {
  position: relative;
  font-size: rem;
  display: inline-block;
  margin-left: 0.625rem;
  color: inherit;
}
.card .card-header .card-controls a i:before {
  font-size: 1rem;
}
.card[class^=card-] .card-header, .card[class*=" card-"] .card-header, .card[class^=bg-] .card-header, .card[class*=" bg-"] .card-header {
  color: inherit;
}
.card .card-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 40px;
  line-height: 40px;
  margin-right: 1rem;
}
.card .card-avatar.widget-icon {
  font-size: 1rem;
}
.card .card-tab > .nav-tabs {
  padding: 1rem 1rem 0;
}
.card .card-body > *:last-child {
  margin-bottom: 0;
}

/* $widget icon
 ------------------------------------------*/
.widget-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
}
.widget-icon::before {
  line-height: 40px;
}

.widget-bg-icon {
  position: absolute;
  right: 15px;
  top: 0;
  line-height: 80px;
  font-size: 60px;
}

.ngb-dp-month-name {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 13px !important;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
}

.ngb-dp-day > div,
.ngb-dp-day > span {
  border-radius: 0;
}

.ngb-dp-months {
  padding: 0 !important;
}

.ng-touched .form-control:invalid ~ .invalid-feedback,
.ng-invalid .form-control:invalid ~ .invalid-feedback,
.ng-invalid + .invalid-feedback {
  display: block;
}

.custom-control {
  align-items: center;
  align-content: center;
}

.table.align-middle th, .table.align-middle td {
  vertical-align: middle;
}

.table th:first-child, .table td:first-child {
  padding-left: 1.25rem;
}

.table th:last-child, .table td:last-child {
  padding-right: 1.25rem;
}

/* No More tables */
@media (max-width: 991.98px) {
  /* Source:: http://elvery.net/demo/responsive-tables/#no-more-tables */
  /* Force table to not be like tables anymore */
  .no-more-tables table,
.no-more-tables thead,
.no-more-tables tbody,
.no-more-tables th,
.no-more-tables td,
.no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .no-more-tables td {
    position: relative;
    padding-left: 50% !important;
    /* Behave  like a "row" */
    border-top: 0 !important;
    text-align: left;
    white-space: normal;
  }

  .no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0.375rem;
    left: 0.375rem;
    padding-right: 0.625rem;
    width: 45%;
    text-align: left;
    white-space: nowrap;
    font-weight: 600;
  }

  /* Label the data */
  .no-more-tables td:before {
    content: attr(data-title);
  }
}
.bg-body {
  background-color: #ffffff;
}

.bg-dark {
  background-color: #333333;
}

.bg-red {
  background-color: #f44336 !important;
  color: white !important;
}

.bg-red-50 {
  background-color: #ffebee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-100 {
  background-color: #ffcdd2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-200 {
  background-color: #ef9a9a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-300 {
  background-color: #e57373 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-400 {
  background-color: #ef5350 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-500 {
  background-color: #f44336 !important;
  color: white !important;
}

.bg-red-600 {
  background-color: #e53935 !important;
  color: white !important;
}

.bg-red-700 {
  background-color: #d32f2f !important;
  color: white !important;
}

.bg-red-800 {
  background-color: #c62828 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-red-900 {
  background-color: #b71c1c !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-red-A100 {
  background-color: #ff8a80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-red-A200 {
  background-color: #ff5252 !important;
  color: white !important;
}

.bg-red-A400 {
  background-color: #ff1744 !important;
  color: white !important;
}

.bg-red-A700 {
  background-color: #d50000 !important;
  color: white !important;
}

.bg-pink {
  background-color: #e91e63 !important;
  color: white !important;
}

.bg-pink-50 {
  background-color: #fce4ec !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-pink-100 {
  background-color: #f8bbd0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-pink-200 {
  background-color: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-pink-300 {
  background-color: #f06292 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-pink-400 {
  background-color: #ec407a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-pink-500 {
  background-color: #e91e63 !important;
  color: white !important;
}

.bg-pink-600 {
  background-color: #d81b60 !important;
  color: white !important;
}

.bg-pink-700 {
  background-color: #c2185b !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-pink-800 {
  background-color: #ad1457 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-pink-900 {
  background-color: #880e4f !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-pink-A100 {
  background-color: #ff80ab !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-pink-A200 {
  background-color: #ff4081 !important;
  color: white !important;
}

.bg-pink-A400 {
  background-color: #f50057 !important;
  color: white !important;
}

.bg-pink-A700 {
  background-color: #c51162 !important;
  color: white !important;
}

.bg-purple {
  background-color: #9c27b0 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-purple-50 {
  background-color: #f3e5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-purple-100 {
  background-color: #e1bee7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-purple-200 {
  background-color: #ce93d8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-purple-300 {
  background-color: #ba68c8 !important;
  color: white !important;
}

.bg-purple-400 {
  background-color: #ab47bc !important;
  color: white !important;
}

.bg-purple-500 {
  background-color: #9c27b0 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-purple-600 {
  background-color: #8e24aa !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-purple-700 {
  background-color: #7b1fa2 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-purple-800 {
  background-color: #6a1b9a !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-purple-900 {
  background-color: #4a148c !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-purple-A100 {
  background-color: #ea80fc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-purple-A200 {
  background-color: #e040fb !important;
  color: white !important;
}

.bg-purple-A400 {
  background-color: #d500f9 !important;
  color: white !important;
}

.bg-purple-A700 {
  background-color: #aa00ff !important;
  color: white !important;
}

.bg-deep-purple {
  background-color: #303030 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-deep-purple-50 {
  background-color: #303030 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-purple-100 {
  background-color: #303030 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-purple-200 {
  background-color: #303030 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-purple-300 {
  background-color: #303030 !important;
  color: white !important;
}

.bg-deep-purple-400 {
  background-color: #303030 !important;
  color: white !important;
}

.bg-deep-purple-500 {
  background-color: #303030 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-deep-purple-600 {
  background-color: #303030 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-deep-purple-700 {
  background-color: #303030 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-deep-purple-800 {
  background-color: #303030 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-deep-purple-900 {
  background-color: #303030 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-deep-purple-A100 {
  background-color: #303030 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-purple-A200 {
  background-color: #303030 !important;
  color: white !important;
}

.bg-deep-purple-A400 {
  background-color: #303030 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-deep-purple-A700 {
  background-color: #303030 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-indigo {
  background-color: #3f51b5 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-indigo-50 {
  background-color: #e8eaf6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-indigo-100 {
  background-color: #c5cae9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-indigo-200 {
  background-color: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-indigo-300 {
  background-color: #7986cb !important;
  color: white !important;
}

.bg-indigo-400 {
  background-color: #5c6bc0 !important;
  color: white !important;
}

.bg-indigo-500 {
  background-color: #3f51b5 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-indigo-600 {
  background-color: #3949ab !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-indigo-700 {
  background-color: #303f9f !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-indigo-800 {
  background-color: #283593 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-indigo-900 {
  background-color: #1a237e !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-indigo-A100 {
  background-color: #8c9eff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-indigo-A200 {
  background-color: #536dfe !important;
  color: white !important;
}

.bg-indigo-A400 {
  background-color: #3d5afe !important;
  color: white !important;
}

.bg-indigo-A700 {
  background-color: #304ffe !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-blue {
  background-color: #2196f3 !important;
  color: white !important;
}

.bg-blue-50 {
  background-color: #e3f2fd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-100 {
  background-color: #bbdefb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-200 {
  background-color: #90caf9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-300 {
  background-color: #64b5f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-400 {
  background-color: #42a5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-500 {
  background-color: #2196f3 !important;
  color: white !important;
}

.bg-blue-600 {
  background-color: #1e88e5 !important;
  color: white !important;
}

.bg-blue-700 {
  background-color: #1976d2 !important;
  color: white !important;
}

.bg-blue-800 {
  background-color: #1565c0 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-blue-900 {
  background-color: #0d47a1 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-blue-A100 {
  background-color: #82b1ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-A200 {
  background-color: #448aff !important;
  color: white !important;
}

.bg-blue-A400 {
  background-color: #2979ff !important;
  color: white !important;
}

.bg-blue-A700 {
  background-color: #2962ff !important;
  color: white !important;
}

.bg-light-blue {
  background-color: #03a9f4 !important;
  color: white !important;
}

.bg-light-blue-50 {
  background-color: #e1f5fe !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-blue-100 {
  background-color: #b3e5fc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-blue-200 {
  background-color: #81d4fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-blue-300 {
  background-color: #4fc3f7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-blue-400 {
  background-color: #29b6f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-blue-500 {
  background-color: #03a9f4 !important;
  color: white !important;
}

.bg-light-blue-600 {
  background-color: #039be5 !important;
  color: white !important;
}

.bg-light-blue-700 {
  background-color: #0288d1 !important;
  color: white !important;
}

.bg-light-blue-800 {
  background-color: #0277bd !important;
  color: white !important;
}

.bg-light-blue-900 {
  background-color: #01579b !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-light-blue-A100 {
  background-color: #80d8ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-blue-A200 {
  background-color: #40c4ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-blue-A400 {
  background-color: #00b0ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-blue-A700 {
  background-color: #0091ea !important;
  color: white !important;
}

.bg-cyan {
  background-color: #00bcd4 !important;
  color: white !important;
}

.bg-cyan-50 {
  background-color: #e0f7fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-cyan-100 {
  background-color: #b2ebf2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-cyan-200 {
  background-color: #80deea !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-cyan-300 {
  background-color: #4dd0e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-cyan-400 {
  background-color: #26c6da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-cyan-500 {
  background-color: #00bcd4 !important;
  color: white !important;
}

.bg-cyan-600 {
  background-color: #00acc1 !important;
  color: white !important;
}

.bg-cyan-700 {
  background-color: #0097a7 !important;
  color: white !important;
}

.bg-cyan-800 {
  background-color: #00838f !important;
  color: white !important;
}

.bg-cyan-900 {
  background-color: #006064 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-cyan-A100 {
  background-color: #84ffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-cyan-A200 {
  background-color: #18ffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-cyan-A400 {
  background-color: #00e5ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-cyan-A700 {
  background-color: #00b8d4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal {
  background-color: #009688 !important;
  color: white !important;
}

.bg-teal-50 {
  background-color: #e0f2f1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal-100 {
  background-color: #b2dfdb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal-200 {
  background-color: #80cbc4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal-300 {
  background-color: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal-400 {
  background-color: #26a69a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal-500 {
  background-color: #009688 !important;
  color: white !important;
}

.bg-teal-600 {
  background-color: #00897b !important;
  color: white !important;
}

.bg-teal-700 {
  background-color: #00796b !important;
  color: white !important;
}

.bg-teal-800 {
  background-color: #00695c !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-teal-900 {
  background-color: #004d40 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-teal-A100 {
  background-color: #a7ffeb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal-A200 {
  background-color: #64ffda !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal-A400 {
  background-color: #1de9b6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-teal-A700 {
  background-color: #00bfa5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green {
  background-color: #4caf50 !important;
  color: white !important;
}

.bg-green-50 {
  background-color: #e8f5e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green-100 {
  background-color: #c8e6c9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green-200 {
  background-color: #a5d6a7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green-300 {
  background-color: #81c784 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green-400 {
  background-color: #66bb6a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green-500 {
  background-color: #4caf50 !important;
  color: white !important;
}

.bg-green-600 {
  background-color: #43a047 !important;
  color: white !important;
}

.bg-green-700 {
  background-color: #388e3c !important;
  color: white !important;
}

.bg-green-800 {
  background-color: #2e7d32 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-green-900 {
  background-color: #1b5e20 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-green-A100 {
  background-color: #b9f6ca !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green-A200 {
  background-color: #69f0ae !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green-A400 {
  background-color: #00e676 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-green-A700 {
  background-color: #00c853 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green {
  background-color: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-50 {
  background-color: #f1f8e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-100 {
  background-color: #dcedc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-200 {
  background-color: #c5e1a5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-300 {
  background-color: #aed581 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-400 {
  background-color: #9ccc65 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-500 {
  background-color: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-600 {
  background-color: #7cb342 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-700 {
  background-color: #689f38 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-800 {
  background-color: #558b2f !important;
  color: white !important;
}

.bg-light-green-900 {
  background-color: #33691e !important;
  color: white !important;
}

.bg-light-green-A100 {
  background-color: #ccff90 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-A200 {
  background-color: #b2ff59 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-A400 {
  background-color: #76ff03 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-light-green-A700 {
  background-color: #64dd17 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime {
  background-color: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-50 {
  background-color: #f9fbe7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-100 {
  background-color: #f0f4c3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-200 {
  background-color: #e6ee9c !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-300 {
  background-color: #dce775 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-400 {
  background-color: #d4e157 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-500 {
  background-color: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-600 {
  background-color: #c0ca33 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-700 {
  background-color: #afb42b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-800 {
  background-color: #9e9d24 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-900 {
  background-color: #827717 !important;
  color: white !important;
}

.bg-lime-A100 {
  background-color: #f4ff81 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-A200 {
  background-color: #eeff41 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-A400 {
  background-color: #c6ff00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-lime-A700 {
  background-color: #aeea00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow {
  background-color: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-50 {
  background-color: #fffde7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-100 {
  background-color: #fff9c4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-200 {
  background-color: #fff59d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-300 {
  background-color: #fff176 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-400 {
  background-color: #ffee58 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-500 {
  background-color: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-600 {
  background-color: #fdd835 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-700 {
  background-color: #fbc02d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-800 {
  background-color: #f9a825 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-900 {
  background-color: #f57f17 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-A100 {
  background-color: #ffff8d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-A200 {
  background-color: yellow !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-A400 {
  background-color: #ffea00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-yellow-A700 {
  background-color: #ffd600 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-50 {
  background-color: #fff8e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-100 {
  background-color: #ffecb3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-200 {
  background-color: #ffe082 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-300 {
  background-color: #ffd54f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-400 {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-500 {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-600 {
  background-color: #ffb300 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-700 {
  background-color: #ffa000 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-800 {
  background-color: #ff8f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-900 {
  background-color: #ff6f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-A100 {
  background-color: #ffe57f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-A200 {
  background-color: #ffd740 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-A400 {
  background-color: #ffc400 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-amber-A700 {
  background-color: #ffab00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-50 {
  background-color: #fff3e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-100 {
  background-color: #ffe0b2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-200 {
  background-color: #ffcc80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-300 {
  background-color: #ffb74d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-400 {
  background-color: #ffa726 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-500 {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-600 {
  background-color: #fb8c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-700 {
  background-color: #f57c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-800 {
  background-color: #ef6c00 !important;
  color: white !important;
}

.bg-orange-900 {
  background-color: #e65100 !important;
  color: white !important;
}

.bg-orange-A100 {
  background-color: #ffd180 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-A200 {
  background-color: #ffab40 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-A400 {
  background-color: #ff9100 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-orange-A700 {
  background-color: #ff6d00 !important;
  color: black !important;
}

.bg-deep-orange {
  background-color: #ff5722 !important;
  color: white !important;
}

.bg-deep-orange-50 {
  background-color: #fbe9e7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-orange-100 {
  background-color: #ffccbc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-orange-200 {
  background-color: #ffab91 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-orange-300 {
  background-color: #ff8a65 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-orange-400 {
  background-color: #ff7043 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-orange-500 {
  background-color: #ff5722 !important;
  color: white !important;
}

.bg-deep-orange-600 {
  background-color: #f4511e !important;
  color: white !important;
}

.bg-deep-orange-700 {
  background-color: #e64a19 !important;
  color: white !important;
}

.bg-deep-orange-800 {
  background-color: #d84315 !important;
  color: white !important;
}

.bg-deep-orange-900 {
  background-color: #bf360c !important;
  color: white !important;
}

.bg-deep-orange-A100 {
  background-color: #ff9e80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-orange-A200 {
  background-color: #ff6e40 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-deep-orange-A400 {
  background-color: #ff3d00 !important;
  color: white !important;
}

.bg-deep-orange-A700 {
  background-color: #dd2c00 !important;
  color: white !important;
}

.bg-brown {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-brown-50 {
  background-color: #efebe9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-brown-100 {
  background-color: #d7ccc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-brown-200 {
  background-color: #bcaaa4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-brown-300 {
  background-color: #a1887f !important;
  color: white !important;
}

.bg-brown-400 {
  background-color: #8d6e63 !important;
  color: white !important;
}

.bg-brown-500 {
  background-color: #795548 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-brown-600 {
  background-color: #6d4c41 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-brown-700 {
  background-color: #5d4037 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-brown-800 {
  background-color: #4e342e !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-brown-900 {
  background-color: #3e2723 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-brown-A100 {
  background-color: #d7ccc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-brown-A200 {
  background-color: #bcaaa4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-brown-A400 {
  background-color: #8d6e63 !important;
  color: white !important;
}

.bg-brown-A700 {
  background-color: #5d4037 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-grey {
  background-color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-50 {
  background-color: #fafafa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-100 {
  background-color: whitesmoke !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-200 {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-300 {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-400 {
  background-color: #bdbdbd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-500 {
  background-color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-600 {
  background-color: #757575 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-grey-700 {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-grey-800 {
  background-color: #424242 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-grey-900 {
  background-color: #212121 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-grey-A100 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-A200 {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-A400 {
  background-color: #bdbdbd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-grey-A700 {
  background-color: #616161 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-blue-grey {
  background-color: #607d8b !important;
  color: white !important;
}

.bg-blue-grey-50 {
  background-color: #eceff1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-grey-100 {
  background-color: #cfd8dc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-grey-200 {
  background-color: #b0bec5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-grey-300 {
  background-color: #90a4ae !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-grey-400 {
  background-color: #78909c !important;
  color: white !important;
}

.bg-blue-grey-500 {
  background-color: #607d8b !important;
  color: white !important;
}

.bg-blue-grey-600 {
  background-color: #546e7a !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-blue-grey-700 {
  background-color: #455a64 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-blue-grey-800 {
  background-color: #37474f !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-blue-grey-900 {
  background-color: #263238 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.bg-blue-grey-A100 {
  background-color: #cfd8dc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-grey-A200 {
  background-color: #b0bec5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bg-blue-grey-A400 {
  background-color: #78909c !important;
  color: white !important;
}

.bg-blue-grey-A700 {
  background-color: #455a64 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

/* Extra text colors */
.text-red {
  color: #f44336 !important;
}

a.text-red:hover, a.text-red:focus {
  color: #d2190b !important;
}

.text-pink {
  color: #e91e63 !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: #aa1145 !important;
}

.text-purple {
  color: #9c27b0 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #641971 !important;
}

.text-deep-purple {
  color: #303030 !important;
}

a.text-deep-purple:hover, a.text-deep-purple:focus {
  color: #0a0a0a !important;
}

.text-indigo {
  color: #3f51b5 !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #2b387c !important;
}

.text-blue {
  color: #2196f3 !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #0a6ebd !important;
}

.text-light-blue {
  color: #03a9f4 !important;
}

a.text-light-blue:hover, a.text-light-blue:focus {
  color: #0275a8 !important;
}

.text-cyan {
  color: #00bcd4 !important;
}

a.text-cyan:hover, a.text-cyan:focus {
  color: #007888 !important;
}

.text-teal {
  color: #009688 !important;
}

a.text-teal:hover, a.text-teal:focus {
  color: #004a43 !important;
}

.text-green {
  color: #4caf50 !important;
}

a.text-green:hover, a.text-green:focus {
  color: #357a38 !important;
}

.text-light-green {
  color: #8bc34a !important;
}

a.text-light-green:hover, a.text-light-green:focus {
  color: #649130 !important;
}

.text-lime {
  color: #cddc39 !important;
}

a.text-lime:hover, a.text-lime:focus {
  color: #9daa1e !important;
}

.text-yellow {
  color: #ffeb3b !important;
}

a.text-yellow:hover, a.text-yellow:focus {
  color: #eed500 !important;
}

.text-amber {
  color: #ffc107 !important;
}

a.text-amber:hover, a.text-amber:focus {
  color: #ba8b00 !important;
}

.text-orange {
  color: #ff9800 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #b36a00 !important;
}

.text-deep-orange {
  color: #ff5722 !important;
}

a.text-deep-orange:hover, a.text-deep-orange:focus {
  color: #d53300 !important;
}

.text-brown {
  color: #795548 !important;
}

a.text-brown:hover, a.text-brown:focus {
  color: #49332b !important;
}

.text-grey {
  color: #9e9e9e !important;
}

a.text-grey:hover, a.text-grey:focus {
  color: #787878 !important;
}

.text-blue-grey {
  color: #607d8b !important;
}

a.text-blue-grey:hover, a.text-blue-grey:focus {
  color: #41545e !important;
}

/* $scaffolding
 ------------------------------------------*/
body,
html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background: #6b6b6b;
  letter-spacing: 0.01rem;
}

.app {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
@media (max-width: 1199.98px) {
  .app {
    transform: none !important;
  }
}

/* $typography
 ------------------------------------------*/
a {
  text-decoration: none;
}
a:hover, a:focus {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: 0.02rem;
}

.blockquote {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8;
  opacity: 0.7;
  border-left: 0;
}
.blockquote::before {
  font-size: 2rem;
  position: relative;
  top: 0.5rem;
  display: inline-block;
  padding-right: 0.625rem;
  content: "“";
  font-weight: bold;
  font-family: Georgia, serif;
}
.blockquote cite {
  font-size: 1rem;
  display: block;
}

.ff-sans {
  font-family: "Tahoma", sans-serif;
}

.ff-serif {
  font-family: "Playfair Display", "Abril Fatface", Georgia, "Times New Roman", Times, serif;
}

.ff-headers {
  font-family: "Montserrat", Helvetica, sans-serif;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-900 {
  font-weight: 900;
}

.no-bg {
  background-color: transparent !important;
}

.text-color {
  color: rgba(0, 0, 0, 0.87);
}

.shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.no-shadow {
  box-shadow: none;
}

/* $avatar
 ------------------------------------------*/
.avatar-status {
  position: relative;
  padding: 2px;
  border-radius: 50%;
  display: inline-block;
}
.avatar-status.bg-none {
  padding: 0;
}

/* sizes */
.avatar-xxlg {
  width: 32rem;
}

.avatar-xlg {
  width: 16rem;
}

.avatar-lg {
  width: 8rem;
}

.avatar-md {
  width: 4rem;
}

.avatar-sm {
  width: 3rem;
}

.avatar-xs {
  width: 2.125rem;
}

.avatar-xxs {
  width: 1.375rem;
}

/* $hamburger-icon
 ------------------------------------------*/
.hamburger-icon {
  tap-highlight-color: transparent;
  display: inline-block;
}
.hamburger-icon:before, .hamburger-icon:after,
.hamburger-icon span {
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  pointer-events: none;
}
.hamburger-icon span {
  margin: 4px 0;
}

.hamburger-icon.v2:before {
  width: 14px;
}
.hamburger-icon.v2 span {
  width: 10px;
}

.hamburger-icon.v3:before {
  height: 1px;
  transform: translateY(0.09rem) translateX(0.38rem) rotate(45deg) scaleX(0.5);
}
.hamburger-icon.v3:after {
  transform: translateY(-0.09rem) translateX(0.44em) rotate(-45deg) scaleX(0.5);
}

/* $layout
 ------------------------------------------*/
/*Heights*/
.min-height-100 {
  min-height: 100%;
}

.page-height {
  height: calc(100vh - 3.5rem) !important;
}

/*Scroll*/
.scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/*Hide footer*/
.no-footer .main-footer {
  display: none;
  visibility: hidden;
}

/* Boxed layout*/
.app.mode-boxed {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .app.mode-boxed {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .app.mode-boxed {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .app.mode-boxed {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .app.mode-boxed {
    max-width: 1140px;
  }
}

/* Boxed layout*/
.map-header .ng-sidebar__content > .main-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: transparent !important;
  box-shadow: none !important;
}
.map-header .ng-sidebar__content > .main-header .nav-link,
.map-header .ng-sidebar__content > .main-header .navbar-heading {
  color: black !important;
  text-shadow: 0px 0px 1px white;
}

/* $NgSidebar
 ------------------------------------------*/
.ng-sidebar__backdrop {
  opacity: 0.6 !important;
}

@media screen and (-ms-high-contrast: active) {
  .ng-sidebar__backdrop {
    opacity: 0.5 !important;
  }
}
ng-sidebar-container {
  position: relative;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
}

ng-sidebar .ng-sidebar {
  position: relative;
  transform: translate3d(0, 0, 0);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  outline: 0;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
}

.ng-sidenav.ng-sidenav--closed {
  visibility: hidden;
}

.ng-sidenav.ng-sidenav--opened, .ng-sidenav.ng-sidenav--opening {
  transform: translate3d(0, 0, 0);
}

.ng-sidebar__content {
  position: relative;
  display: block;
  height: 100%;
  overflow: auto;
}

.app > .ng-sidebar__content {
  padding: 0 !important;
}

/* $sidebar-panel
 ------------------------------------------*/
.app ng-sidebar > .sidebar-panel.ng-sidebar {
  width: 17rem;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: width 200ms, transform 200ms cubic-bezier(0, 0, 0.3, 1);
  padding-right: 10px;
  box-shadow: 0 0 15px 0 rgba(82, 63, 105, 0.15);
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-sidebar {
  overflow: hidden;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu {
  position: relative;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  margin-right: -35px;
  padding-right: 35px;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-item {
  position: relative;
  display: block;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-item.open > a > .menu-caret {
  transform: rotate(90deg);
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-item.open > .navigation-submenu {
  max-height: 1000px;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-submenu a {
  padding: 0.4rem 1rem 0.4rem 2rem;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-link {
  position: relative;
  padding: 10px 5px 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  transition: background 200ms, color 200ms;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-link:hover, .app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-link:focus {
  background-color: transparent;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-link span {
  display: inline-block;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-link [class^=icon-]:not(.menu-caret),
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-link [class*=" icon-"]:not(.menu-caret) {
  width: 20px;
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1rem;
  line-height: 1;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-submenu {
  position: relative;
  z-index: 1;
  overflow: hidden;
  max-height: 0;
  transition: 0.5s ease;
  transition-property: max-height;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-submenu a {
  padding: 0.4rem 1rem 0.4rem 2.28rem;
}
.app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .menu-caret {
  display: inline-block;
  transition: transform 200ms ease;
  float: right;
  line-height: 1;
  width: 16px;
  height: 16px;
}
@media (min-width: 576px) {
  .app ng-sidebar > .sidebar-panel.ng-sidebar .image-align img.sm {
    display: none;
  }
}
@media (min-width: 992px) {
  .app ng-sidebar > .sidebar-panel.ng-sidebar {
    z-index: 1030;
    width: 17rem;
    /* Docked sidebar */
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar app-menu {
    min-width: 17rem;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) {
    width: 5rem;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) .navigation-submenu,
.app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) .docked-hidden {
    display: none !important;
    visibility: hidden;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) .main-brand {
    flex-direction: column;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) .main-brand .image-align img.lg {
    display: none;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) .main-brand .image-align img.sm {
    display: inline-block;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) .main-brand .navbar-brand {
    width: 100%;
    text-align: center;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) app-menu {
    min-width: 5rem;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) app-menu .navigation-link {
    padding-right: 0;
    padding-left: 0;
    display: block;
    width: 100%;
    text-align: center;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) app-menu .navigation-link span,
.app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) app-menu .navigation-link .menu-caret {
    display: none;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:not(:hover) app-menu .icon {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:hover .hover-hidden {
    display: none !important;
    visibility: hidden;
  }
  .app ng-sidebar > .sidebar-panel.ng-sidebar.ng-sidebar--dock:hover .docked-hidden {
    margin-left: -10px !important;
  }
}

::ng-deep .theme-light .sidebar-panel.ng-sidebar .navigation-link {
  color: #ffffff !important;
}

/* $header
 ------------------------------------------*/
.custom-navbar {
  position: relative;
  z-index: 1000;
  padding-top: 0;
  padding-right: 15px;
  padding-bottom: 0;
  padding-left: 0;
  min-height: 4.5rem;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.custom-navbar .navbar-nav {
  flex-direction: row;
}
.custom-navbar .navbar-nav .nav-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
}
.custom-navbar .navbar-nav .nav-link {
  line-height: 2.5rem;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 1001;
  margin-top: -1px;
}
.custom-navbar .navbar-nav .nav-link .badge {
  position: absolute;
  bottom: 10px;
  right: 5px;
  border-radius: 50%;
  min-height: 20px;
  min-width: 20px;
}
.custom-navbar .navbar-nav .nav-link.dropdown > a {
  line-height: 3.5rem;
  display: inline-block;
}
.custom-navbar .navbar-brand {
  font-weight: bold;
  color: white;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin: 0;
}
.custom-navbar .navbar-brand .navbar-brand-logo {
  max-height: 27px;
}
.custom-navbar .navbar-avatar {
  width: 30px;
}
.custom-navbar .navbar-heading {
  font-size: 0.73rem;
  font-weight: 700;
  display: inline-block;
  margin-right: 1rem;
}
.custom-navbar .nav-divider {
  height: 16px;
  width: 1px;
  display: inline;
  position: relative;
}
.custom-navbar .navbar-nav > li > a {
  padding-top: 1.156rem;
  padding-bottom: 1.156rem;
}
.custom-navbar .navbar-nav .dropdown-menu {
  position: absolute;
  left: auto !important;
  right: -1px !important;
  line-height: initial;
  min-width: auto;
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
.custom-navbar .navbar-nav .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
}
.custom-navbar .navbar-nav .dropdown-menu.notifications {
  min-width: 360px;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 575.98px) {
  .custom-navbar .navbar-nav .dropdown-menu.notifications {
    position: fixed;
    left: 0;
    right: 0;
    top: 3.5rem;
    min-width: auto;
  }
}
.custom-navbar .navbar-nav .dropdown-menu.notifications .notifications-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 280px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.custom-navbar .navbar-nav .dropdown-menu.notifications .notifications-wrapper a {
  position: relative;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: auto;
}
.custom-navbar .navbar-nav .dropdown-menu.notifications .notifications-wrapper a:last-child {
  border-bottom: 0;
}
.custom-navbar .navbar-nav .dropdown-menu.notifications .notifications-wrapper .time {
  font-size: 0.72rem;
  line-height: 1;
  display: block;
  color: #6c757d;
}
.custom-navbar .navbar-nav .dropdown-menu.notifications .notification-footer {
  padding: 0.7rem 1rem;
  background-color: rgba(0, 0, 0, 0.075);
}
.custom-navbar .navbar-nav .show::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ffffff;
  z-index: 1001;
}
.custom-navbar .navbar-nav .show {
  outline: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.main-brand {
  padding: 0 1rem;
  background-color: #ffffff;
}

.main-header .dropdown-toggle::after {
  display: none;
}

/* Layout for search container */
/* Inspiration https://tympanus.net/codrops/2017/02/08/inspiration-search-ui-effects/ */
.search {
  overflow: hidden;
  width: 100hw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
}
.search .modal-dialog {
  margin: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.search .modal-content {
  background: transparent;
  border: 0;
  padding: 3rem;
  width: 100%;
  height: 100%;
}

.search-close {
  position: fixed;
  z-index: 9000;
  top: 19px;
  right: 18px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
  line-height: 1;
}

.search-input {
  font-family: inherit;
  font-size: 6vw;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 0.05rem 0;
  color: #000;
  border: 0;
  border-bottom: 2px solid;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
}
.search-input:hover, .search-input:focus {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
  border-color: theme-colors("primary");
}
.search-input::-webkit-input-placeholder {
  color: #6c757d;
}
.search-input::-moz-placeholder {
  opacity: 1;
  color: #6c757d;
}
.search-input:-ms-input-placeholder {
  color: #6c757d;
}
.search-input::-webkit-search-cancel-button, .search-input::-webkit-search-decoration {
  -webkit-appearance: none;
}
.search-input::-ms-clear {
  display: none;
}

.search-suggestions {
  width: 35%;
  padding: 4rem 0 0 0;
  pointer-events: none;
}

/* $main-panel
 ------------------------------------------*/
.ng-sidebar__content {
  min-height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
}
.ng-sidebar__content .app-inner {
  flex-direction: column;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1;
  height: 100%;
  overflow: hidden;
}
.ng-sidebar__content .main-content {
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  margin: 0;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}
.ng-sidebar__content .main-content .ng-sidebar {
  position: absolute !important;
}
.ng-sidebar__content .main-content .router-outlet {
  position: relative;
  box-sizing: border-box;
  padding: 10px 0.8rem 0 10px;
  margin: 0;
  display: block;
  flex: 1 0 auto;
  min-height: calc(100vh - 90px);
}

@media (min-width: 992px) {
  .app:not(.mode-slide) {
    transform: none !important;
  }

  .mode-slide {
    overflow: scroll !important;
  }

  .mode-slide > .ng-sidebar__content {
    overflow: visible !important;
  }

  .mode-push .app-inner {
    margin-left: 17rem !important;
  }

  .mode-dock .app-inner {
    margin-left: 5rem !important;
  }

  .mode-over .app-inner,
.app:not(.sidebar-opened) .app-inner {
    margin-left: 0 !important;
  }
}
.media-grid,
.media-tile {
  list-style: none;
  text-align: center;
}
.media-grid figure,
.media-tile figure {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  background: #333333;
  text-align: left;
  border-radius: 0.125rem;
}
.media-grid figure img,
.media-tile figure img {
  position: relative;
  display: block;
  max-width: 100%;
  opacity: 0.8;
}

.media-grid {
  text-align: center;
}
.media-grid figure {
  text-align: center;
}
.media-grid figure figcaption {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  padding: 1rem;
}
.media-grid figure figcaption::before,
.media-grid figure figcaption::after {
  pointer-events: none;
  position: absolute;
  width: 150%;
  height: 150%;
  border-style: solid;
  border-color: #333333;
  content: "";
  transition: transform 200ms;
}
.media-grid figure figcaption::before {
  right: 0;
  bottom: 0;
  border-width: 0 45px 40px 0;
  transform: translate3d(45px, 40px, 0);
}
.media-grid figure figcaption::after {
  top: 0;
  left: 0;
  border-width: 15px 0 0 15px;
  transform: translate3d(-15px, -15px, 0);
}
.media-grid figure figcaption,
.media-grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}
.media-grid figure .media-title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.4em 10px;
  width: 50%;
  transition: transform 200ms;
  transform: translate3d(100%, 0, 0);
}
.media-grid figure .media-title,
.media-grid figure p {
  margin: 0;
}
.media-grid figure p {
  float: right;
  clear: both;
  text-align: left;
  text-transform: none;
  font-size: 1rem;
}
.media-grid figure img,
.media-grid figure p a {
  transition: opacity 200ms, transform 200ms;
}
.media-grid figure img {
  opacity: 0.85;
}
.media-grid figure p a {
  display: block;
  margin-bottom: 1rem;
  color: #fff;
  opacity: 0;
  transform: translate3d(90px, 0, 0);
}
.media-grid figure:hover figcaption::before,
.media-grid figure:hover figcaption::after {
  transform: translate3d(0, 0, 0);
}
.media-grid figure:hover img {
  opacity: 0.6;
}
.media-grid figure:hover .media-title,
.media-grid figure:hover p a {
  transform: translate3d(0, 0, 0);
}
.media-grid figure:hover p a {
  opacity: 1;
}
.media-grid figure:hover p a:first-child {
  transition-delay: 0.025s;
}
.media-grid figure:hover p a:nth-child(2) {
  transition-delay: 0.05s;
}
.media-grid figure:hover p a:nth-child(3) {
  transition-delay: 0.075s;
}
.media-grid figure:hover p a:nth-child(4) {
  transition-delay: 0.1s;
}

.media-tile figcaption {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  color: #fff;
  background: rgba(0, 0, 0, 0.38);
  overflow: hidden;
  padding: 1rem;
  position: absolute;
  left: 0;
  right: 0;
}
.media-tile figcaption .media-title,
.media-tile figcaption .media-subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.media-tile.media-tile-footer figcaption {
  bottom: 0;
  border-bottom-left-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.media-tile.media-tile-header figcaption {
  top: 0;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.ngx-datatable {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  /**
   * Shared Styles
   */
  /**
   * Global Row Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
}
.ngx-datatable.fullscreen {
  position: absolute !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
}
.ngx-datatable.scroll-vertical {
  height: 70vh;
}
.ngx-datatable:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  transition: background 200ms linear;
}
.ngx-datatable.cell-selection .datatable-body-cell:hover, .ngx-datatable.cell-selection .datatable-body-cell:hover .datatable-row-group {
  transition: background 200ms linear;
}
.ngx-datatable .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable .datatable-header .datatable-row-left,
.ngx-datatable .datatable-body .datatable-row-left {
  background-color: white;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable .datatable-header .datatable-row-right,
.ngx-datatable .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: white;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable .datatable-header {
  border-bottom: 1px solid #dee2e6;
}
.ngx-datatable .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  font-weight: 400;
  color: #757575;
  vertical-align: bottom;
}
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 200ms ease, opacity 200ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable .datatable-header .resize-handle {
  border-right: solid 1px rgba(0, 0, 0, 0.05);
}
.ngx-datatable .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  transition: width 200ms ease;
}
.ngx-datatable .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.ngx-datatable .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable .datatable-body .progress-linear .container .bar {
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 200ms linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;
}
.ngx-datatable .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable .datatable-footer .datatable-pager {
  margin: 10px;
  box-sizing: border-box;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  justify-content: flex-end;
  align-content: stretch;
  align-items: stretch;
  -webkit-box-align: stretch;
  -webkit-box-pack: end;
}
.ngx-datatable .datatable-footer .datatable-pager .pager {
  display: flex !important;
  padding-left: 0;
  list-style: none;
  border-radius: 0.125rem;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li:first-child a {
  margin-left: 0;
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li:last-child a {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li.active a {
  z-index: 2;
  color: #fff;
  background-color: #304ffe;
  border-color: #304ffe;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #304ffe;
  opacity: 0.4;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #304ffe;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a:hover, .ngx-datatable .datatable-footer .datatable-pager .pager li a:focus {
  color: #0123e0;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a i {
  height: 100%;
  box-sizing: border-box;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  justify-content: center;
  align-content: center;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  font-size: 20px;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  transition: all 200ms ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: white;
  cursor: pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
/* $widget
 ------------------------------------------*/
.activity-stream {
  position: relative;
}
.activity-stream::before {
  content: "";
  position: absolute;
  top: 42px;
  bottom: 0;
  left: 2.5rem;
  width: 1px;
}
.activity-stream .card, .activity-stream .card-header {
  background-color: transparent !important;
  border: 0;
}
.activity-stream .card-header::after {
  display: none;
}
.activity-stream .card-body {
  padding-top: 0;
  padding-left: 78px;
}

.bg-cover {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

body {
  text-rendering: auto !important;
  -webkit-font-smoothing: auto !important;
}

.bg-1 {
  background-image: url("./../../../images/login-background/default.png");
}

.bg-none {
  background-image: linear-gradient(135deg, #fcfcfc 4%, #fcfcfc 7%, #29b8e5 50%, #bce0ee 76%, #bce0ee 84%, #bce0ee 88%, #ffffff 99%);
}

.lockcode {
  display: block;
  max-width: 280px;
  margin: 0 auto;
}

.error-number {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 60px;
  line-height: 1;
}