mat-table,
.mat-table {
  width: 100% !important;
  border: 1px solid #f1f1f1;
  border-collapse: separate;
}

mat-header-row,
mat-row,
mat-footer-row,
.mat-header-row,
.mat-row,
.mat-footer-row {
  min-height: auto !important;
  height: auto !important;
}

mat-header-row,
.mat-header-row {
  /*background-color: #353c49 !important;*/
  background-color: #2a4858;   
  text-align: left !important;
}

mat-footer-row,
.mat-footer-row {
  background-color: #39ab96 !important;
}

mat-header-cell,
.mat-header-cell {
  color: #ffffff !important;
  text-align: left !important;
  border-left: 1px solid #949090 !important;
}

mat-footer-cell,
.mat-footer-cell {
  color: #ffffff !important;
  font-weight: 100;
}

mat-header-cell,
mat-cell,
mat-footer-cell,
.mat-header-cell,
.mat-cell,
.mat-footer-cell {
  padding: 5px !important;
  font-size: 12px !important;
  border-bottom-width: 0 !important;
}

.mat-row:nth-child(even) {
  background-color: #f1f1f1 !important;
}

.mat-row:nth-child(odd) {
  background-color: #ffffff !important;
}

mat-cell .mat-icon,
.mat-cell .mat-icon {
  cursor: pointer !important;
  text-align: right !important;
}
