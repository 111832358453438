.bg-body { background-color: $body-bg;}
.bg-dark { background-color: rgba(51, 51, 51, 1.00);}

.bg-red { background-color: mat-color($mat-red, 500)!important; color: mat-contrast($mat-red, 500)!important;}
.bg-red-50 { background-color: mat-color($mat-red, 50)!important; color: mat-contrast($mat-red, 50)!important;}
.bg-red-100 { background-color: mat-color($mat-red, 100)!important; color: mat-contrast($mat-red, 100)!important;}
.bg-red-200 { background-color: mat-color($mat-red, 200)!important; color: mat-contrast($mat-red, 200)!important;}
.bg-red-300 { background-color: mat-color($mat-red, 300)!important; color: mat-contrast($mat-red, 300)!important;}
.bg-red-400 { background-color: mat-color($mat-red, 400)!important; color: mat-contrast($mat-red, 400)!important;}
.bg-red-500 { background-color: mat-color($mat-red, 500)!important; color: mat-contrast($mat-red, 500)!important;}
.bg-red-600 { background-color: mat-color($mat-red, 600)!important; color: mat-contrast($mat-red, 600)!important;}
.bg-red-700 { background-color: mat-color($mat-red, 700)!important; color: mat-contrast($mat-red, 700)!important;}
.bg-red-800 { background-color: mat-color($mat-red, 800)!important; color: mat-contrast($mat-red, 800)!important;}
.bg-red-900 { background-color: mat-color($mat-red, 900)!important; color: mat-contrast($mat-red, 900)!important;}
.bg-red-A100 { background-color: mat-color($mat-red, A100)!important; color: mat-contrast($mat-red, A100)!important;}
.bg-red-A200 { background-color: mat-color($mat-red, A200)!important; color: mat-contrast($mat-red, A200)!important;}
.bg-red-A400 { background-color: mat-color($mat-red, A400)!important; color: mat-contrast($mat-red, A400)!important;}
.bg-red-A700 { background-color: mat-color($mat-red, A700)!important; color: mat-contrast($mat-red, A700)!important;}

.bg-pink { background-color: mat-color($mat-pink, 500)!important; color: mat-contrast($mat-pink, 500)!important;}
.bg-pink-50 { background-color: mat-color($mat-pink, 50)!important; color: mat-contrast($mat-pink, 50)!important;}
.bg-pink-100 { background-color: mat-color($mat-pink, 100)!important; color: mat-contrast($mat-pink, 100)!important;}
.bg-pink-200 { background-color: mat-color($mat-pink, 200)!important; color: mat-contrast($mat-pink, 200)!important;}
.bg-pink-300 { background-color: mat-color($mat-pink, 300)!important; color: mat-contrast($mat-pink, 300)!important;}
.bg-pink-400 { background-color: mat-color($mat-pink, 400)!important; color: mat-contrast($mat-pink, 400)!important;}
.bg-pink-500 { background-color: mat-color($mat-pink, 500)!important; color: mat-contrast($mat-pink, 500)!important;}
.bg-pink-600 { background-color: mat-color($mat-pink, 600)!important; color: mat-contrast($mat-pink, 600)!important;}
.bg-pink-700 { background-color: mat-color($mat-pink, 700)!important; color: mat-contrast($mat-pink, 700)!important;}
.bg-pink-800 { background-color: mat-color($mat-pink, 800)!important; color: mat-contrast($mat-pink, 800)!important;}
.bg-pink-900 { background-color: mat-color($mat-pink, 900)!important; color: mat-contrast($mat-pink, 900)!important;}
.bg-pink-A100 { background-color: mat-color($mat-pink, A100)!important; color: mat-contrast($mat-pink, A100)!important;}
.bg-pink-A200 { background-color: mat-color($mat-pink, A200)!important; color: mat-contrast($mat-pink, A200)!important;}
.bg-pink-A400 { background-color: mat-color($mat-pink, A400)!important; color: mat-contrast($mat-pink, A400)!important;}
.bg-pink-A700 { background-color: mat-color($mat-pink, A700)!important; color: mat-contrast($mat-pink, A700)!important;}

.bg-purple { background-color: mat-color($mat-purple, 500)!important; color: mat-contrast($mat-purple, 500)!important;}
.bg-purple-50 { background-color: mat-color($mat-purple, 50)!important; color: mat-contrast($mat-purple, 50)!important;}
.bg-purple-100 { background-color: mat-color($mat-purple, 100)!important; color: mat-contrast($mat-purple, 100)!important;}
.bg-purple-200 { background-color: mat-color($mat-purple, 200)!important; color: mat-contrast($mat-purple, 200)!important;}
.bg-purple-300 { background-color: mat-color($mat-purple, 300)!important; color: mat-contrast($mat-purple, 300)!important;}
.bg-purple-400 { background-color: mat-color($mat-purple, 400)!important; color: mat-contrast($mat-purple, 400)!important;}
.bg-purple-500 { background-color: mat-color($mat-purple, 500)!important; color: mat-contrast($mat-purple, 500)!important;}
.bg-purple-600 { background-color: mat-color($mat-purple, 600)!important; color: mat-contrast($mat-purple, 600)!important;}
.bg-purple-700 { background-color: mat-color($mat-purple, 700)!important; color: mat-contrast($mat-purple, 700)!important;}
.bg-purple-800 { background-color: mat-color($mat-purple, 800)!important; color: mat-contrast($mat-purple, 800)!important;}
.bg-purple-900 { background-color: mat-color($mat-purple, 900)!important; color: mat-contrast($mat-purple, 900)!important;}
.bg-purple-A100 { background-color: mat-color($mat-purple, A100)!important; color: mat-contrast($mat-purple, A100)!important;}
.bg-purple-A200 { background-color: mat-color($mat-purple, A200)!important; color: mat-contrast($mat-purple, A200)!important;}
.bg-purple-A400 { background-color: mat-color($mat-purple, A400)!important; color: mat-contrast($mat-purple, A400)!important;}
.bg-purple-A700 { background-color: mat-color($mat-purple, A700)!important; color: mat-contrast($mat-purple, A700)!important;}

.bg-deep-purple { background-color: mat-color($mat-deep-purple, 500)!important; color: mat-contrast($mat-deep-purple, 500)!important;}
.bg-deep-purple-50 { background-color: mat-color($mat-deep-purple, 50)!important; color: mat-contrast($mat-deep-purple, 50)!important;}
.bg-deep-purple-100 { background-color: mat-color($mat-deep-purple, 100)!important; color: mat-contrast($mat-deep-purple, 100)!important;}
.bg-deep-purple-200 { background-color: mat-color($mat-deep-purple, 200)!important; color: mat-contrast($mat-deep-purple, 200)!important;}
.bg-deep-purple-300 { background-color: mat-color($mat-deep-purple, 300)!important; color: mat-contrast($mat-deep-purple, 300)!important;}
.bg-deep-purple-400 { background-color: mat-color($mat-deep-purple, 400)!important; color: mat-contrast($mat-deep-purple, 400)!important;}
.bg-deep-purple-500 { background-color: mat-color($mat-deep-purple, 500)!important; color: mat-contrast($mat-deep-purple, 500)!important;}
.bg-deep-purple-600 { background-color: mat-color($mat-deep-purple, 600)!important; color: mat-contrast($mat-deep-purple, 600)!important;}
.bg-deep-purple-700 { background-color: mat-color($mat-deep-purple, 700)!important; color: mat-contrast($mat-deep-purple, 700)!important;}
.bg-deep-purple-800 { background-color: mat-color($mat-deep-purple, 800)!important; color: mat-contrast($mat-deep-purple, 800)!important;}
.bg-deep-purple-900 { background-color: mat-color($mat-deep-purple, 900)!important; color: mat-contrast($mat-deep-purple, 900)!important;}
.bg-deep-purple-A100 { background-color: mat-color($mat-deep-purple, A100)!important; color: mat-contrast($mat-deep-purple, A100)!important;}
.bg-deep-purple-A200 { background-color: mat-color($mat-deep-purple, A200)!important; color: mat-contrast($mat-deep-purple, A200)!important;}
.bg-deep-purple-A400 { background-color: mat-color($mat-deep-purple, A400)!important; color: mat-contrast($mat-deep-purple, A400)!important;}
.bg-deep-purple-A700 { background-color: mat-color($mat-deep-purple, A700)!important; color: mat-contrast($mat-deep-purple, A700)!important;}

.bg-indigo { background-color: mat-color($mat-indigo, 500)!important; color: mat-contrast($mat-indigo, 500)!important;}
.bg-indigo-50 { background-color: mat-color($mat-indigo, 50)!important; color: mat-contrast($mat-indigo, 50)!important;}
.bg-indigo-100 { background-color: mat-color($mat-indigo, 100)!important; color: mat-contrast($mat-indigo, 100)!important;}
.bg-indigo-200 { background-color: mat-color($mat-indigo, 200)!important; color: mat-contrast($mat-indigo, 200)!important;}
.bg-indigo-300 { background-color: mat-color($mat-indigo, 300)!important; color: mat-contrast($mat-indigo, 300)!important;}
.bg-indigo-400 { background-color: mat-color($mat-indigo, 400)!important; color: mat-contrast($mat-indigo, 400)!important;}
.bg-indigo-500 { background-color: mat-color($mat-indigo, 500)!important; color: mat-contrast($mat-indigo, 500)!important;}
.bg-indigo-600 { background-color: mat-color($mat-indigo, 600)!important; color: mat-contrast($mat-indigo, 600)!important;}
.bg-indigo-700 { background-color: mat-color($mat-indigo, 700)!important; color: mat-contrast($mat-indigo, 700)!important;}
.bg-indigo-800 { background-color: mat-color($mat-indigo, 800)!important; color: mat-contrast($mat-indigo, 800)!important;}
.bg-indigo-900 { background-color: mat-color($mat-indigo, 900)!important; color: mat-contrast($mat-indigo, 900)!important;}
.bg-indigo-A100 { background-color: mat-color($mat-indigo, A100)!important; color: mat-contrast($mat-indigo, A100)!important;}
.bg-indigo-A200 { background-color: mat-color($mat-indigo, A200)!important; color: mat-contrast($mat-indigo, A200)!important;}
.bg-indigo-A400 { background-color: mat-color($mat-indigo, A400)!important; color: mat-contrast($mat-indigo, A400)!important;}
.bg-indigo-A700 { background-color: mat-color($mat-indigo, A700)!important; color: mat-contrast($mat-indigo, A700)!important;}

.bg-blue { background-color: mat-color($mat-blue, 500)!important; color: mat-contrast($mat-blue, 500)!important;}
.bg-blue-50 { background-color: mat-color($mat-blue, 50)!important; color: mat-contrast($mat-blue, 50)!important;}
.bg-blue-100 { background-color: mat-color($mat-blue, 100)!important; color: mat-contrast($mat-blue, 100)!important;}
.bg-blue-200 { background-color: mat-color($mat-blue, 200)!important; color: mat-contrast($mat-blue, 200)!important;}
.bg-blue-300 { background-color: mat-color($mat-blue, 300)!important; color: mat-contrast($mat-blue, 300)!important;}
.bg-blue-400 { background-color: mat-color($mat-blue, 400)!important; color: mat-contrast($mat-blue, 400)!important;}
.bg-blue-500 { background-color: mat-color($mat-blue, 500)!important; color: mat-contrast($mat-blue, 500)!important;}
.bg-blue-600 { background-color: mat-color($mat-blue, 600)!important; color: mat-contrast($mat-blue, 600)!important;}
.bg-blue-700 { background-color: mat-color($mat-blue, 700)!important; color: mat-contrast($mat-blue, 700)!important;}
.bg-blue-800 { background-color: mat-color($mat-blue, 800)!important; color: mat-contrast($mat-blue, 800)!important;}
.bg-blue-900 { background-color: mat-color($mat-blue, 900)!important; color: mat-contrast($mat-blue, 900)!important;}
.bg-blue-A100 { background-color: mat-color($mat-blue, A100)!important; color: mat-contrast($mat-blue, A100)!important;}
.bg-blue-A200 { background-color: mat-color($mat-blue, A200)!important; color: mat-contrast($mat-blue, A200)!important;}
.bg-blue-A400 { background-color: mat-color($mat-blue, A400)!important; color: mat-contrast($mat-blue, A400)!important;}
.bg-blue-A700 { background-color: mat-color($mat-blue, A700)!important; color: mat-contrast($mat-blue, A700)!important;}

.bg-light-blue { background-color: mat-color($mat-light-blue, 500)!important; color: mat-contrast($mat-light-blue, 500)!important;}
.bg-light-blue-50 { background-color: mat-color($mat-light-blue, 50)!important; color: mat-contrast($mat-light-blue, 50)!important;}
.bg-light-blue-100 { background-color: mat-color($mat-light-blue, 100)!important; color: mat-contrast($mat-light-blue, 100)!important;}
.bg-light-blue-200 { background-color: mat-color($mat-light-blue, 200)!important; color: mat-contrast($mat-light-blue, 200)!important;}
.bg-light-blue-300 { background-color: mat-color($mat-light-blue, 300)!important; color: mat-contrast($mat-light-blue, 300)!important;}
.bg-light-blue-400 { background-color: mat-color($mat-light-blue, 400)!important; color: mat-contrast($mat-light-blue, 400)!important;}
.bg-light-blue-500 { background-color: mat-color($mat-light-blue, 500)!important; color: mat-contrast($mat-light-blue, 500)!important;}
.bg-light-blue-600 { background-color: mat-color($mat-light-blue, 600)!important; color: mat-contrast($mat-light-blue, 600)!important;}
.bg-light-blue-700 { background-color: mat-color($mat-light-blue, 700)!important; color: mat-contrast($mat-light-blue, 700)!important;}
.bg-light-blue-800 { background-color: mat-color($mat-light-blue, 800)!important; color: mat-contrast($mat-light-blue, 800)!important;}
.bg-light-blue-900 { background-color: mat-color($mat-light-blue, 900)!important; color: mat-contrast($mat-light-blue, 900)!important;}
.bg-light-blue-A100 { background-color: mat-color($mat-light-blue, A100)!important; color: mat-contrast($mat-light-blue, A100)!important;}
.bg-light-blue-A200 { background-color: mat-color($mat-light-blue, A200)!important; color: mat-contrast($mat-light-blue, A200)!important;}
.bg-light-blue-A400 { background-color: mat-color($mat-light-blue, A400)!important; color: mat-contrast($mat-light-blue, A400)!important;}
.bg-light-blue-A700 { background-color: mat-color($mat-light-blue, A700)!important; color: mat-contrast($mat-light-blue, A700)!important;}

.bg-cyan { background-color: mat-color($mat-cyan, 500)!important; color: mat-contrast($mat-cyan, 500)!important;}
.bg-cyan-50 { background-color: mat-color($mat-cyan, 50)!important; color: mat-contrast($mat-cyan, 50)!important;}
.bg-cyan-100 { background-color: mat-color($mat-cyan, 100)!important; color: mat-contrast($mat-cyan, 100)!important;}
.bg-cyan-200 { background-color: mat-color($mat-cyan, 200)!important; color: mat-contrast($mat-cyan, 200)!important;}
.bg-cyan-300 { background-color: mat-color($mat-cyan, 300)!important; color: mat-contrast($mat-cyan, 300)!important;}
.bg-cyan-400 { background-color: mat-color($mat-cyan, 400)!important; color: mat-contrast($mat-cyan, 400)!important;}
.bg-cyan-500 { background-color: mat-color($mat-cyan, 500)!important; color: mat-contrast($mat-cyan, 500)!important;}
.bg-cyan-600 { background-color: mat-color($mat-cyan, 600)!important; color: mat-contrast($mat-cyan, 600)!important;}
.bg-cyan-700 { background-color: mat-color($mat-cyan, 700)!important; color: mat-contrast($mat-cyan, 700)!important;}
.bg-cyan-800 { background-color: mat-color($mat-cyan, 800)!important; color: mat-contrast($mat-cyan, 800)!important;}
.bg-cyan-900 { background-color: mat-color($mat-cyan, 900)!important; color: mat-contrast($mat-cyan, 900)!important;}
.bg-cyan-A100 { background-color: mat-color($mat-cyan, A100)!important; color: mat-contrast($mat-cyan, A100)!important;}
.bg-cyan-A200 { background-color: mat-color($mat-cyan, A200)!important; color: mat-contrast($mat-cyan, A200)!important;}
.bg-cyan-A400 { background-color: mat-color($mat-cyan, A400)!important; color: mat-contrast($mat-cyan, A400)!important;}
.bg-cyan-A700 { background-color: mat-color($mat-cyan, A700)!important; color: mat-contrast($mat-cyan, A700)!important;}

.bg-teal { background-color: mat-color($mat-teal, 500)!important; color: mat-contrast($mat-teal, 500)!important;}
.bg-teal-50 { background-color: mat-color($mat-teal, 50)!important; color: mat-contrast($mat-teal, 50)!important;}
.bg-teal-100 { background-color: mat-color($mat-teal, 100)!important; color: mat-contrast($mat-teal, 100)!important;}
.bg-teal-200 { background-color: mat-color($mat-teal, 200)!important; color: mat-contrast($mat-teal, 200)!important;}
.bg-teal-300 { background-color: mat-color($mat-teal, 300)!important; color: mat-contrast($mat-teal, 300)!important;}
.bg-teal-400 { background-color: mat-color($mat-teal, 400)!important; color: mat-contrast($mat-teal, 400)!important;}
.bg-teal-500 { background-color: mat-color($mat-teal, 500)!important; color: mat-contrast($mat-teal, 500)!important;}
.bg-teal-600 { background-color: mat-color($mat-teal, 600)!important; color: mat-contrast($mat-teal, 600)!important;}
.bg-teal-700 { background-color: mat-color($mat-teal, 700)!important; color: mat-contrast($mat-teal, 700)!important;}
.bg-teal-800 { background-color: mat-color($mat-teal, 800)!important; color: mat-contrast($mat-teal, 800)!important;}
.bg-teal-900 { background-color: mat-color($mat-teal, 900)!important; color: mat-contrast($mat-teal, 900)!important;}
.bg-teal-A100 { background-color: mat-color($mat-teal, A100)!important; color: mat-contrast($mat-teal, A100)!important;}
.bg-teal-A200 { background-color: mat-color($mat-teal, A200)!important; color: mat-contrast($mat-teal, A200)!important;}
.bg-teal-A400 { background-color: mat-color($mat-teal, A400)!important; color: mat-contrast($mat-teal, A400)!important;}
.bg-teal-A700 { background-color: mat-color($mat-teal, A700)!important; color: mat-contrast($mat-teal, A700)!important;}

.bg-green { background-color: mat-color($mat-green, 500)!important; color: mat-contrast($mat-green, 500)!important;}
.bg-green-50 { background-color: mat-color($mat-green, 50)!important; color: mat-contrast($mat-green, 50)!important;}
.bg-green-100 { background-color: mat-color($mat-green, 100)!important; color: mat-contrast($mat-green, 100)!important;}
.bg-green-200 { background-color: mat-color($mat-green, 200)!important; color: mat-contrast($mat-green, 200)!important;}
.bg-green-300 { background-color: mat-color($mat-green, 300)!important; color: mat-contrast($mat-green, 300)!important;}
.bg-green-400 { background-color: mat-color($mat-green, 400)!important; color: mat-contrast($mat-green, 400)!important;}
.bg-green-500 { background-color: mat-color($mat-green, 500)!important; color: mat-contrast($mat-green, 500)!important;}
.bg-green-600 { background-color: mat-color($mat-green, 600)!important; color: mat-contrast($mat-green, 600)!important;}
.bg-green-700 { background-color: mat-color($mat-green, 700)!important; color: mat-contrast($mat-green, 700)!important;}
.bg-green-800 { background-color: mat-color($mat-green, 800)!important; color: mat-contrast($mat-green, 800)!important;}
.bg-green-900 { background-color: mat-color($mat-green, 900)!important; color: mat-contrast($mat-green, 900)!important;}
.bg-green-A100 { background-color: mat-color($mat-green, A100)!important; color: mat-contrast($mat-green, A100)!important;}
.bg-green-A200 { background-color: mat-color($mat-green, A200)!important; color: mat-contrast($mat-green, A200)!important;}
.bg-green-A400 { background-color: mat-color($mat-green, A400)!important; color: mat-contrast($mat-green, A400)!important;}
.bg-green-A700 { background-color: mat-color($mat-green, A700)!important; color: mat-contrast($mat-green, A700)!important;}

.bg-light-green { background-color: mat-color($mat-light-green, 500)!important; color: mat-contrast($mat-light-green, 500)!important;}
.bg-light-green-50 { background-color: mat-color($mat-light-green, 50)!important; color: mat-contrast($mat-light-green, 50)!important;}
.bg-light-green-100 { background-color: mat-color($mat-light-green, 100)!important; color: mat-contrast($mat-light-green, 100)!important;}
.bg-light-green-200 { background-color: mat-color($mat-light-green, 200)!important; color: mat-contrast($mat-light-green, 200)!important;}
.bg-light-green-300 { background-color: mat-color($mat-light-green, 300)!important; color: mat-contrast($mat-light-green, 300)!important;}
.bg-light-green-400 { background-color: mat-color($mat-light-green, 400)!important; color: mat-contrast($mat-light-green, 400)!important;}
.bg-light-green-500 { background-color: mat-color($mat-light-green, 500)!important; color: mat-contrast($mat-light-green, 500)!important;}
.bg-light-green-600 { background-color: mat-color($mat-light-green, 600)!important; color: mat-contrast($mat-light-green, 600)!important;}
.bg-light-green-700 { background-color: mat-color($mat-light-green, 700)!important; color: mat-contrast($mat-light-green, 700)!important;}
.bg-light-green-800 { background-color: mat-color($mat-light-green, 800)!important; color: mat-contrast($mat-light-green, 800)!important;}
.bg-light-green-900 { background-color: mat-color($mat-light-green, 900)!important; color: mat-contrast($mat-light-green, 900)!important;}
.bg-light-green-A100 { background-color: mat-color($mat-light-green, A100)!important; color: mat-contrast($mat-light-green, A100)!important;}
.bg-light-green-A200 { background-color: mat-color($mat-light-green, A200)!important; color: mat-contrast($mat-light-green, A200)!important;}
.bg-light-green-A400 { background-color: mat-color($mat-light-green, A400)!important; color: mat-contrast($mat-light-green, A400)!important;}
.bg-light-green-A700 { background-color: mat-color($mat-light-green, A700)!important; color: mat-contrast($mat-light-green, A700)!important;}

.bg-lime { background-color: mat-color($mat-lime, 500)!important; color: mat-contrast($mat-lime, 500)!important;}
.bg-lime-50 { background-color: mat-color($mat-lime, 50)!important; color: mat-contrast($mat-lime, 50)!important;}
.bg-lime-100 { background-color: mat-color($mat-lime, 100)!important; color: mat-contrast($mat-lime, 100)!important;}
.bg-lime-200 { background-color: mat-color($mat-lime, 200)!important; color: mat-contrast($mat-lime, 200)!important;}
.bg-lime-300 { background-color: mat-color($mat-lime, 300)!important; color: mat-contrast($mat-lime, 300)!important;}
.bg-lime-400 { background-color: mat-color($mat-lime, 400)!important; color: mat-contrast($mat-lime, 400)!important;}
.bg-lime-500 { background-color: mat-color($mat-lime, 500)!important; color: mat-contrast($mat-lime, 500)!important;}
.bg-lime-600 { background-color: mat-color($mat-lime, 600)!important; color: mat-contrast($mat-lime, 600)!important;}
.bg-lime-700 { background-color: mat-color($mat-lime, 700)!important; color: mat-contrast($mat-lime, 700)!important;}
.bg-lime-800 { background-color: mat-color($mat-lime, 800)!important; color: mat-contrast($mat-lime, 800)!important;}
.bg-lime-900 { background-color: mat-color($mat-lime, 900)!important; color: mat-contrast($mat-lime, 900)!important;}
.bg-lime-A100 { background-color: mat-color($mat-lime, A100)!important; color: mat-contrast($mat-lime, A100)!important;}
.bg-lime-A200 { background-color: mat-color($mat-lime, A200)!important; color: mat-contrast($mat-lime, A200)!important;}
.bg-lime-A400 { background-color: mat-color($mat-lime, A400)!important; color: mat-contrast($mat-lime, A400)!important;}
.bg-lime-A700 { background-color: mat-color($mat-lime, A700)!important; color: mat-contrast($mat-lime, A700)!important;}

.bg-yellow { background-color: mat-color($mat-yellow, 500)!important; color: mat-contrast($mat-yellow, 500)!important;}
.bg-yellow-50 { background-color: mat-color($mat-yellow, 50)!important; color: mat-contrast($mat-yellow, 50)!important;}
.bg-yellow-100 { background-color: mat-color($mat-yellow, 100)!important; color: mat-contrast($mat-yellow, 100)!important;}
.bg-yellow-200 { background-color: mat-color($mat-yellow, 200)!important; color: mat-contrast($mat-yellow, 200)!important;}
.bg-yellow-300 { background-color: mat-color($mat-yellow, 300)!important; color: mat-contrast($mat-yellow, 300)!important;}
.bg-yellow-400 { background-color: mat-color($mat-yellow, 400)!important; color: mat-contrast($mat-yellow, 400)!important;}
.bg-yellow-500 { background-color: mat-color($mat-yellow, 500)!important; color: mat-contrast($mat-yellow, 500)!important;}
.bg-yellow-600 { background-color: mat-color($mat-yellow, 600)!important; color: mat-contrast($mat-yellow, 600)!important;}
.bg-yellow-700 { background-color: mat-color($mat-yellow, 700)!important; color: mat-contrast($mat-yellow, 700)!important;}
.bg-yellow-800 { background-color: mat-color($mat-yellow, 800)!important; color: mat-contrast($mat-yellow, 800)!important;}
.bg-yellow-900 { background-color: mat-color($mat-yellow, 900)!important; color: mat-contrast($mat-yellow, 900)!important;}
.bg-yellow-A100 { background-color: mat-color($mat-yellow, A100)!important; color: mat-contrast($mat-yellow, A100)!important;}
.bg-yellow-A200 { background-color: mat-color($mat-yellow, A200)!important; color: mat-contrast($mat-yellow, A200)!important;}
.bg-yellow-A400 { background-color: mat-color($mat-yellow, A400)!important; color: mat-contrast($mat-yellow, A400)!important;}
.bg-yellow-A700 { background-color: mat-color($mat-yellow, A700)!important; color: mat-contrast($mat-yellow, A700)!important;}

.bg-amber { background-color: mat-color($mat-amber, 500)!important; color: mat-contrast($mat-amber, 500)!important;}
.bg-amber-50 { background-color: mat-color($mat-amber, 50)!important; color: mat-contrast($mat-amber, 50)!important;}
.bg-amber-100 { background-color: mat-color($mat-amber, 100)!important; color: mat-contrast($mat-amber, 100)!important;}
.bg-amber-200 { background-color: mat-color($mat-amber, 200)!important; color: mat-contrast($mat-amber, 200)!important;}
.bg-amber-300 { background-color: mat-color($mat-amber, 300)!important; color: mat-contrast($mat-amber, 300)!important;}
.bg-amber-400 { background-color: mat-color($mat-amber, 400)!important; color: mat-contrast($mat-amber, 400)!important;}
.bg-amber-500 { background-color: mat-color($mat-amber, 500)!important; color: mat-contrast($mat-amber, 500)!important;}
.bg-amber-600 { background-color: mat-color($mat-amber, 600)!important; color: mat-contrast($mat-amber, 600)!important;}
.bg-amber-700 { background-color: mat-color($mat-amber, 700)!important; color: mat-contrast($mat-amber, 700)!important;}
.bg-amber-800 { background-color: mat-color($mat-amber, 800)!important; color: mat-contrast($mat-amber, 800)!important;}
.bg-amber-900 { background-color: mat-color($mat-amber, 900)!important; color: mat-contrast($mat-amber, 900)!important;}
.bg-amber-A100 { background-color: mat-color($mat-amber, A100)!important; color: mat-contrast($mat-amber, A100)!important;}
.bg-amber-A200 { background-color: mat-color($mat-amber, A200)!important; color: mat-contrast($mat-amber, A200)!important;}
.bg-amber-A400 { background-color: mat-color($mat-amber, A400)!important; color: mat-contrast($mat-amber, A400)!important;}
.bg-amber-A700 { background-color: mat-color($mat-amber, A700)!important; color: mat-contrast($mat-amber, A700)!important;}

.bg-orange { background-color: mat-color($mat-orange, 500)!important; color: mat-contrast($mat-orange, 500)!important;}
.bg-orange-50 { background-color: mat-color($mat-orange, 50)!important; color: mat-contrast($mat-orange, 50)!important;}
.bg-orange-100 { background-color: mat-color($mat-orange, 100)!important; color: mat-contrast($mat-orange, 100)!important;}
.bg-orange-200 { background-color: mat-color($mat-orange, 200)!important; color: mat-contrast($mat-orange, 200)!important;}
.bg-orange-300 { background-color: mat-color($mat-orange, 300)!important; color: mat-contrast($mat-orange, 300)!important;}
.bg-orange-400 { background-color: mat-color($mat-orange, 400)!important; color: mat-contrast($mat-orange, 400)!important;}
.bg-orange-500 { background-color: mat-color($mat-orange, 500)!important; color: mat-contrast($mat-orange, 500)!important;}
.bg-orange-600 { background-color: mat-color($mat-orange, 600)!important; color: mat-contrast($mat-orange, 600)!important;}
.bg-orange-700 { background-color: mat-color($mat-orange, 700)!important; color: mat-contrast($mat-orange, 700)!important;}
.bg-orange-800 { background-color: mat-color($mat-orange, 800)!important; color: mat-contrast($mat-orange, 800)!important;}
.bg-orange-900 { background-color: mat-color($mat-orange, 900)!important; color: mat-contrast($mat-orange, 900)!important;}
.bg-orange-A100 { background-color: mat-color($mat-orange, A100)!important; color: mat-contrast($mat-orange, A100)!important;}
.bg-orange-A200 { background-color: mat-color($mat-orange, A200)!important; color: mat-contrast($mat-orange, A200)!important;}
.bg-orange-A400 { background-color: mat-color($mat-orange, A400)!important; color: mat-contrast($mat-orange, A400)!important;}
.bg-orange-A700 { background-color: mat-color($mat-orange, A700)!important; color: mat-contrast($mat-orange, A700)!important;}

.bg-deep-orange { background-color: mat-color($mat-deep-orange, 500)!important; color: mat-contrast($mat-deep-orange, 500)!important;}
.bg-deep-orange-50 { background-color: mat-color($mat-deep-orange, 50)!important; color: mat-contrast($mat-deep-orange, 50)!important;}
.bg-deep-orange-100 { background-color: mat-color($mat-deep-orange, 100)!important; color: mat-contrast($mat-deep-orange, 100)!important;}
.bg-deep-orange-200 { background-color: mat-color($mat-deep-orange, 200)!important; color: mat-contrast($mat-deep-orange, 200)!important;}
.bg-deep-orange-300 { background-color: mat-color($mat-deep-orange, 300)!important; color: mat-contrast($mat-deep-orange, 300)!important;}
.bg-deep-orange-400 { background-color: mat-color($mat-deep-orange, 400)!important; color: mat-contrast($mat-deep-orange, 400)!important;}
.bg-deep-orange-500 { background-color: mat-color($mat-deep-orange, 500)!important; color: mat-contrast($mat-deep-orange, 500)!important;}
.bg-deep-orange-600 { background-color: mat-color($mat-deep-orange, 600)!important; color: mat-contrast($mat-deep-orange, 600)!important;}
.bg-deep-orange-700 { background-color: mat-color($mat-deep-orange, 700)!important; color: mat-contrast($mat-deep-orange, 700)!important;}
.bg-deep-orange-800 { background-color: mat-color($mat-deep-orange, 800)!important; color: mat-contrast($mat-deep-orange, 800)!important;}
.bg-deep-orange-900 { background-color: mat-color($mat-deep-orange, 900)!important; color: mat-contrast($mat-deep-orange, 900)!important;}
.bg-deep-orange-A100 { background-color: mat-color($mat-deep-orange, A100)!important; color: mat-contrast($mat-deep-orange, A100)!important;}
.bg-deep-orange-A200 { background-color: mat-color($mat-deep-orange, A200)!important; color: mat-contrast($mat-deep-orange, A200)!important;}
.bg-deep-orange-A400 { background-color: mat-color($mat-deep-orange, A400)!important; color: mat-contrast($mat-deep-orange, A400)!important;}
.bg-deep-orange-A700 { background-color: mat-color($mat-deep-orange, A700)!important; color: mat-contrast($mat-deep-orange, A700)!important;}

.bg-brown { background-color: mat-color($mat-brown, 500)!important; color: mat-contrast($mat-brown, 500)!important;}
.bg-brown-50 { background-color: mat-color($mat-brown, 50)!important; color: mat-contrast($mat-brown, 50)!important;}
.bg-brown-100 { background-color: mat-color($mat-brown, 100)!important; color: mat-contrast($mat-brown, 100)!important;}
.bg-brown-200 { background-color: mat-color($mat-brown, 200)!important; color: mat-contrast($mat-brown, 200)!important;}
.bg-brown-300 { background-color: mat-color($mat-brown, 300)!important; color: mat-contrast($mat-brown, 300)!important;}
.bg-brown-400 { background-color: mat-color($mat-brown, 400)!important; color: mat-contrast($mat-brown, 400)!important;}
.bg-brown-500 { background-color: mat-color($mat-brown, 500)!important; color: mat-contrast($mat-brown, 500)!important;}
.bg-brown-600 { background-color: mat-color($mat-brown, 600)!important; color: mat-contrast($mat-brown, 600)!important;}
.bg-brown-700 { background-color: mat-color($mat-brown, 700)!important; color: mat-contrast($mat-brown, 700)!important;}
.bg-brown-800 { background-color: mat-color($mat-brown, 800)!important; color: mat-contrast($mat-brown, 800)!important;}
.bg-brown-900 { background-color: mat-color($mat-brown, 900)!important; color: mat-contrast($mat-brown, 900)!important;}
.bg-brown-A100 { background-color: mat-color($mat-brown, A100)!important; color: mat-contrast($mat-brown, A100)!important;}
.bg-brown-A200 { background-color: mat-color($mat-brown, A200)!important; color: mat-contrast($mat-brown, A200)!important;}
.bg-brown-A400 { background-color: mat-color($mat-brown, A400)!important; color: mat-contrast($mat-brown, A400)!important;}
.bg-brown-A700 { background-color: mat-color($mat-brown, A700)!important; color: mat-contrast($mat-brown, A700)!important;}

.bg-grey { background-color: mat-color($mat-grey, 500)!important; color: mat-contrast($mat-grey, 500)!important;}
.bg-grey-50 { background-color: mat-color($mat-grey, 50)!important; color: mat-contrast($mat-grey, 50)!important;}
.bg-grey-100 { background-color: mat-color($mat-grey, 100)!important; color: mat-contrast($mat-grey, 100)!important;}
.bg-grey-200 { background-color: mat-color($mat-grey, 200)!important; color: mat-contrast($mat-grey, 200)!important;}
.bg-grey-300 { background-color: mat-color($mat-grey, 300)!important; color: mat-contrast($mat-grey, 300)!important;}
.bg-grey-400 { background-color: mat-color($mat-grey, 400)!important; color: mat-contrast($mat-grey, 400)!important;}
.bg-grey-500 { background-color: mat-color($mat-grey, 500)!important; color: mat-contrast($mat-grey, 500)!important;}
.bg-grey-600 { background-color: mat-color($mat-grey, 600)!important; color: mat-contrast($mat-grey, 600)!important;}
.bg-grey-700 { background-color: mat-color($mat-grey, 700)!important; color: mat-contrast($mat-grey, 700)!important;}
.bg-grey-800 { background-color: mat-color($mat-grey, 800)!important; color: mat-contrast($mat-grey, 800)!important;}
.bg-grey-900 { background-color: mat-color($mat-grey, 900)!important; color: mat-contrast($mat-grey, 900)!important;}
.bg-grey-A100 { background-color: mat-color($mat-grey, A100)!important; color: mat-contrast($mat-grey, A100)!important;}
.bg-grey-A200 { background-color: mat-color($mat-grey, A200)!important; color: mat-contrast($mat-grey, A200)!important;}
.bg-grey-A400 { background-color: mat-color($mat-grey, A400)!important; color: mat-contrast($mat-grey, A400)!important;}
.bg-grey-A700 { background-color: mat-color($mat-grey, A700)!important; color: mat-contrast($mat-grey, A700)!important;}

.bg-blue-grey { background-color: mat-color($mat-blue-grey, 500)!important; color: mat-contrast($mat-blue-grey, 500)!important;}
.bg-blue-grey-50 { background-color: mat-color($mat-blue-grey, 50)!important; color: mat-contrast($mat-blue-grey, 50)!important;}
.bg-blue-grey-100 { background-color: mat-color($mat-blue-grey, 100)!important; color: mat-contrast($mat-blue-grey, 100)!important;}
.bg-blue-grey-200 { background-color: mat-color($mat-blue-grey, 200)!important; color: mat-contrast($mat-blue-grey, 200)!important;}
.bg-blue-grey-300 { background-color: mat-color($mat-blue-grey, 300)!important; color: mat-contrast($mat-blue-grey, 300)!important;}
.bg-blue-grey-400 { background-color: mat-color($mat-blue-grey, 400)!important; color: mat-contrast($mat-blue-grey, 400)!important;}
.bg-blue-grey-500 { background-color: mat-color($mat-blue-grey, 500)!important; color: mat-contrast($mat-blue-grey, 500)!important;}
.bg-blue-grey-600 { background-color: mat-color($mat-blue-grey, 600)!important; color: mat-contrast($mat-blue-grey, 600)!important;}
.bg-blue-grey-700 { background-color: mat-color($mat-blue-grey, 700)!important; color: mat-contrast($mat-blue-grey, 700)!important;}
.bg-blue-grey-800 { background-color: mat-color($mat-blue-grey, 800)!important; color: mat-contrast($mat-blue-grey, 800)!important;}
.bg-blue-grey-900 { background-color: mat-color($mat-blue-grey, 900)!important; color: mat-contrast($mat-blue-grey, 900)!important;}
.bg-blue-grey-A100 { background-color: mat-color($mat-blue-grey, A100)!important; color: mat-contrast($mat-blue-grey, A100)!important;}
.bg-blue-grey-A200 { background-color: mat-color($mat-blue-grey, A200)!important; color: mat-contrast($mat-blue-grey, A200)!important;}
.bg-blue-grey-A400 { background-color: mat-color($mat-blue-grey, A400)!important; color: mat-contrast($mat-blue-grey, A400)!important;}
.bg-blue-grey-A700 { background-color: mat-color($mat-blue-grey, A700)!important; color: mat-contrast($mat-blue-grey, A700)!important;}

/* Extra text colors */

@include text-emphasis-variant('.text-red', mat-color($mat-red, 500));
@include text-emphasis-variant('.text-pink', mat-color($mat-pink, 500));
@include text-emphasis-variant('.text-purple', mat-color($mat-purple, 500));
@include text-emphasis-variant('.text-deep-purple', mat-color($mat-deep-purple, 500));
@include text-emphasis-variant('.text-indigo', mat-color($mat-indigo, 500));
@include text-emphasis-variant('.text-blue', mat-color($mat-blue, 500));
@include text-emphasis-variant('.text-light-blue', mat-color($mat-light-blue, 500));
@include text-emphasis-variant('.text-cyan', mat-color($mat-cyan, 500));
@include text-emphasis-variant('.text-teal', mat-color($mat-teal, 500));
@include text-emphasis-variant('.text-green', mat-color($mat-green, 500));
@include text-emphasis-variant('.text-light-green', mat-color($mat-light-green, 500));
@include text-emphasis-variant('.text-lime', mat-color($mat-lime, 500));
@include text-emphasis-variant('.text-yellow', mat-color($mat-yellow, 500));
@include text-emphasis-variant('.text-amber', mat-color($mat-amber, 500));
@include text-emphasis-variant('.text-orange', mat-color($mat-orange, 500));
@include text-emphasis-variant('.text-deep-orange', mat-color($mat-deep-orange, 500));
@include text-emphasis-variant('.text-brown', mat-color($mat-brown, 500));
@include text-emphasis-variant('.text-grey', mat-color($mat-grey, 500));
@include text-emphasis-variant('.text-blue-grey', mat-color($mat-blue-grey, 500));
