/* $sidebar-panel
 ------------------------------------------*/
.app ng-sidebar > .sidebar-panel.ng-sidebar {
  width: $sidebar-width;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: width $transition-duration,
    transform $transition-duration cubic-bezier(0, 0, 0.3, 1);
  padding-right: 10px;
  box-shadow: 0 0 15px 0 rgba(82, 63, 105, 0.15);

  app-sidebar {
    overflow: hidden;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  app-menu {
    position: relative;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    margin-right: -35px;
    padding-right: 35px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .navigation-item {
      position: relative;
      display: block;
      &.open > a > .menu-caret {
        transform: rotate(90deg);
      }
      &.open > .navigation-submenu {
        max-height: 1000px;
      }
    }
    .app ng-sidebar > .sidebar-panel.ng-sidebar app-menu .navigation-submenu a {
      padding: 0.4rem 1rem 0.4rem 2rem;
    }
    .navigation-link {
      position: relative;
      padding: 10px 5px 10px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      transition: background $transition-duration, color $transition-duration;
      @include hover-focus {
        background-color: transparent;
      }
      span {
        display: inline-block;
      }
      [class^="icon-"]:not(.menu-caret),
      [class*=" icon-"]:not(.menu-caret) {
        width: 20px;
        text-align: center;
        margin-right: 0.5rem;
        font-size: 1rem;
        line-height: 1;
      }
    }
    .navigation-submenu {
      position: relative;
      z-index: 1;
      overflow: hidden;
      max-height: 0;
      transition: 0.5s ease;
      transition-property: max-height;
      a {
        padding: 0.4rem $spacer 0.4rem 2.28rem;
      }
    }
    .menu-caret {
      display: inline-block;
      transition: transform $transition-duration ease;
      float: right;
      line-height: 1;
      width: 16px;
      height: 16px;
    }
  }
  @include media-breakpoint-up(sm) {
    .image-align img.sm {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    z-index: 1030;
    width: $sidebar-width;
    app-menu {
      min-width: $sidebar-width;
    }
    /* Docked sidebar */
    &.ng-sidebar--dock:not(:hover) {
      width: $sidebar-small-menu-width;
      .navigation-submenu,
      .docked-hidden {
        display: none !important;
        visibility: hidden;
      }
      .main-brand {
        flex-direction: column;

        .image-align img.lg {
          display: none;
        }

        .image-align img.sm {
          display: inline-block;
        }

        .navbar-brand {
          width: 100%;
          text-align: center;
        }
      }
      app-menu {
        min-width: $sidebar-small-menu-width;
        .navigation-link {
          padding-right: 0;
          padding-left: 0;
          display: block;
          width: 100%;
          text-align: center;
          span,
          .menu-caret {
            display: none;
          }
        }
        .icon {
          width: 100%;
          padding: 0;
          margin: 0;
        }
      }
    }
    &.ng-sidebar--dock:hover {
      .hover-hidden {
        display: none !important;
        visibility: hidden;
      }
      .docked-hidden {
        margin-left: -10px !important;
      }
    }
  }
}
::ng-deep .theme-light .sidebar-panel.ng-sidebar .navigation-link {
  color: #ffffff !important;
}
