ngb-accordion > .card {
  background-color: transparent;
  margin-bottom: 5px;
  .card-header {
    a {
      color: inherit;
    }
    &::after {
      display: none;
    }
    &.active::after {
      display: block;
    }
  }
}